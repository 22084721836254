import { Badge, Tabs } from 'antd';
import { ROUTES } from '../../../common/constants';
import MyBreadcrumb from '../../../components/MyBreadcrumb';
import useRouter from '../../../hooks/useRouter';
import AdditionalNotices from './components/AdditionalNotices';
import NoticeOrder from './components/NoticeOrder';

const Notice = () => {
  const { navigate } = useRouter();
  const badgeColor = '#FFF';

  return (
    <div className="container">
      <div className="mt-16 mb-16">
        <MyBreadcrumb />
      </div>
      <div className="notices-tabs d-flex justify-between align-center">
        <Tabs
          onChange={(val) => {
            navigate(
              `/${ROUTES?.INDIRECT_TAX}/${+val === 1 ? ROUTES?.NOTICE_ORDERS : ROUTES?.ADDITIONAL_NOTICE_ORDERS}`,
              {
                replace: true,
              },
            );
          }}
          defaultActiveKey="1"
          className="full-width gst-tabs"
          items={[
            {
              label: (
                <span className="d-flex gap-8">
                  NOTICES & ORDERS
                  <Badge count={2} color={badgeColor} />
                </span>
              ),
              key: '1',
              children: <NoticeOrder />,
            },
            {
              label: (
                <span className="d-flex gap-8">
                  ADDITIONAL NOTICES & ORDERS
                  <Badge count={2} color={badgeColor} />
                </span>
              ),
              key: '2',
              children: <AdditionalNotices />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Notice;
