import { Spin } from 'antd';

type LoaderComponentPropsType = {
  spinning?: boolean;
  size?: 'large' | 'small' | 'default';
  children?: React.ReactNode;
  setHeight?: number;
};

const LoaderComponent = (props: LoaderComponentPropsType) => {
  const {
    spinning = true,
    size = 'default',
    children,
    setHeight = 100,
    ...rest
  } = props;
  return (
    <Spin
      spinning={spinning}
      size={size}
      className="d-flex justify-center align-center flex-vertical"
      style={{
        height: `${setHeight}vh`,
      }}
      {...rest}
    >
      {children}
    </Spin>
  );
};
export default LoaderComponent;
