export const indirectTaxThemeConfig = {
  token: {
    colorPrimary: '#04724d',
    radiusBase: '4px',
    controlRadius: '4px',
    colorLink: '#04724d',
    colorLinkActive: '#04724d',
    colorLinkHover: '#04724d',
    colorBgLayout: 'var(--background-2)',
    fontFamily: 'var(--font-family)',
  },
  components: {
    Typography: {},
    Button: {
      borderRadius: 8,
      colorPrimary: '#04724d',
      colorPrimaryHover: '#04724d',
      colorPrimaryActive: '#04724d',
      colorLinkHover: '#04724d',
      colorLinkActive: '#04724d',
      defaultColor: '#04724d',
      defaultBorderColor: '#04724d',
      primaryShadow: 'unset',
      colorTextDisabled: '#F4F4F4',
      colorBgContainerDisabled: '#04724d',
      borderColorDisabled: '#04724d',
      colorText: '#04724d',
      colorLink: '#04724d',
      controlHeight: 40,
      fontSize: 16,
    },
    Menu: { itemSelectedBg: 'var(--primary-Tint-02)' },
    Table: {
      headerBg: 'var(--color-grey-light)',
      headerColor: 'var(--text-medium)',
      fontWeightStrong: 500,
      rowSelectedBg: '#FBF9FB',
      rowSelectedHoverBg: '#FBF9FB',
    },
    Form: {
      labelFontSize: 16,
      itemMarginBottom: 16,
    },
    Breadcrumb: {
      fontSize: 12,
    },
    Input: {
      activeShadow: 'unset',
      controlHeight: 40,
      colorTextPlaceholder: 'var(--text-medium)',
    },
    Select: {
      controlOutlineWidth: 0,
      controlHeight: 40,
      activeBorderColor: '#04724d',
      hoverBorderColor: '#04724d',
      optionSelectedBg: 'rgb(221,237,232)',
    },
    DatePicker: {
      activeShadow: 'unset',
      colorError: 'rgb(201,24,27)',
      // activeBg: 'rgb(156,104,104)',
      activeBorderColor: 'rgb(103,107,113)',
      hoverBorderColor: '#04724d',
      controlHeight: 40,
    },
    Tag: {
      lineWidth: 0,
    },
    Badge: {
      fontSize: 12,
    },
    Modal: {
      colorBgMask: 'rgba(0, 0, 0, 0.70)',
      titleFontSize: 20,
    },
    Checkbox: {
      colorPrimary: 'var(--text-medium)',
      colorPrimaryHover: 'var(--text-medium)',
      colorPrimaryBorder: 'var(--text-medium)',
    },
    Collapse: {
      colorBorder: 'rgb(255,255,255)',
      headerBg: 'rgba(255,255,255,0.02)',
    },
  },
};
