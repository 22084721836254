import { Button, Card, Collapse, Typography } from 'antd';
import { EMPTY_STATE } from '../../../common/constants';
import MyBreadcrumb from '../../../components/MyBreadcrumb';

import { useQuery } from '@apollo/client';
import { DownArrow, UpArrow } from '../../../assets/svg';
import TableComponent from '../../../components/CommonTable';
import LoaderComponent from '../../../components/LoaderComponent';
import useRouter from '../../../hooks/useRouter';
import { COMPANY } from '../companies/graphql/Queries';
import ProfileDetailCard from './components/ProfileDetailCard';

const { Text } = Typography;

type Intimations = {
  title: string;
  dataIndex: string;
  key: string;
};

const intimationsColumns: Intimations[] = [
  {
    title: 'Type',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Reference no.',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Issue Date',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Reply Due Date',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Section Number',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Attachments',
    dataIndex: 'pan',
    key: 'pan',
  },
];

const noticesColumns: Intimations[] = [
  {
    title: 'Type',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Reference no.',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Issue Date',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Reply Due Date',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Issued by',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Personal Hearing',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Section Number',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Financial Year',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Subject',
    dataIndex: 'pan',
    key: 'pan',
  },
];

const repliesColumns: Intimations[] = [
  {
    title: 'Type/Reply No.',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Reply Type',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Filed Against',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Reply Date',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Original Due Date',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Filed By',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Personal Hearing',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Attachments',
    dataIndex: 'pan',
    key: 'pan',
  },
];

const ordersColumns: Intimations[] = [
  {
    title: 'Type',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Order No.',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Order Date',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Passed by',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Attachments',
    dataIndex: 'pan',
    key: 'pan',
  },
];

const AdditionalNoticeOrderDetail = () => {
  const { params } = useRouter();
  const { id } = params;

  const { data: company, loading } = useQuery(COMPANY, {
    fetchPolicy: 'network-only',
    onError() {},
    variables: { where: { id: id } },
  });

  return (
    <div className="container">
      <LoaderComponent spinning={loading}>
        <div className="mt-16 mb-16 d-flex justify-between">
          <MyBreadcrumb />
        </div>
        <ProfileDetailCard title="Notices & Orders" data={company?.company} />
        <Card className="mb-24">
          <Collapse
            className="table-collapse"
            defaultActiveKey={['1']}
            items={[
              {
                key: '1',
                label: <Text className="title">Intimations</Text>,
                children: (
                  <TableComponent<Intimations>
                    rowKey=""
                    columns={intimationsColumns as []}
                    dataSource={[]}
                    locale={EMPTY_STATE}
                  />
                ),
              },
            ]}
            expandIcon={({ isActive }) => (
              <Button
                icon={!isActive ? <DownArrow /> : <UpArrow />}
                className="justify-center"
              />
            )}
            expandIconPosition="end"
            accordion={false}
          />
        </Card>
        <Card className="mb-24">
          <Collapse
            defaultActiveKey={['1']}
            className="table-collapse"
            items={[
              {
                key: '1',
                label: <Text className="title">Notices</Text>,
                children: (
                  <TableComponent<Intimations>
                    rowKey=""
                    columns={noticesColumns as []}
                    dataSource={[]}
                    locale={EMPTY_STATE}
                  />
                ),
              },
            ]}
            expandIcon={({ isActive }) => (
              <Button
                icon={!isActive ? <DownArrow /> : <UpArrow />}
                className="justify-center"
              />
            )}
            expandIconPosition="end"
            accordion={false}
          />
        </Card>
        <Card className="mb-24">
          <Collapse
            defaultActiveKey={['1']}
            className="table-collapse"
            items={[
              {
                key: '1',
                label: <Text className="title">Replies</Text>,
                children: (
                  <TableComponent<Intimations>
                    rowKey=""
                    columns={repliesColumns as []}
                    dataSource={[]}
                    locale={EMPTY_STATE}
                  />
                ),
              },
            ]}
            expandIcon={({ isActive }) => (
              <Button
                icon={!isActive ? <DownArrow /> : <UpArrow />}
                className="justify-center"
              />
            )}
            expandIconPosition="end"
            accordion={false}
          />
        </Card>
        <Card className="mb-24">
          <Collapse
            defaultActiveKey={['1']}
            className="table-collapse"
            items={[
              {
                key: '1',
                label: <Text className="title">Orders</Text>,
                children: (
                  <TableComponent<Intimations>
                    rowKey=""
                    columns={ordersColumns as []}
                    dataSource={[]}
                    locale={EMPTY_STATE}
                  />
                ),
              },
            ]}
            expandIcon={({ isActive }) => (
              <Button
                icon={!isActive ? <DownArrow /> : <UpArrow />}
                className="justify-center"
              />
            )}
            expandIconPosition="end"
            accordion={false}
          />
        </Card>
      </LoaderComponent>
    </div>
  );
};

export default AdditionalNoticeOrderDetail;
