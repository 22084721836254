import { useQuery } from '@apollo/client';
import { Button, Card, Collapse, Typography } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { AdditionalNotice, Reply } from '../../../__generated__/graphql';
import { DownArrow, UpArrow } from '../../../assets/svg';
import { COMMON_QUERY_PARAMS, EMPTY_STATE } from '../../../common/constants';
import TableComponent from '../../../components/CommonTable';
import LoaderComponent from '../../../components/LoaderComponent';
import MyBreadcrumb from '../../../components/MyBreadcrumb';
import { COMPANY } from '../companies/graphql/Queries';
import ProfileDetailCard from './components/ProfileDetailCard';

const { Text } = Typography;

const intimationsColumns = [
  {
    title: 'Type',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Reference no.',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Issue Date',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Due Date to Reply',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Section No.',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Attachments',
    dataIndex: 'pan',
    key: 'pan',
  },
];

const noticesColumns = [
  {
    title: 'Type',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Reference no.',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Issue Date',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Due Date to Replye',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Notice Issued by',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Personal Hearing',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Section No.',
    dataIndex: 'pan',
    key: 'pan',
  },
];

const repliesColumns = [
  {
    title: 'Type/Reply No.',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Reply Type',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Reply Filed Against',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Reply Date',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Original Due Date',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Filed Up',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Option for personal hearing',
    dataIndex: 'pan',
    key: 'pan',
  },
];

const ordersColumns = [
  {
    title: 'Type',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Order No.',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Order Date',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Passed by',
    dataIndex: 'pan',
    key: 'pan',
  },
  {
    title: 'Attachments',
    dataIndex: 'pan',
    key: 'pan',
  },
];

const NoticesOrdersDetail = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get(COMMON_QUERY_PARAMS.NOTICE_ID);
  const { data: company, loading } = useQuery(COMPANY, {
    fetchPolicy: 'network-only',
    onError() {},
    variables: { where: { id: id } },
  });
  /* kept for future use */
  /* const { data: { additionalNotice = {} } = {}, loading: otherDetailsLoading } =
    useQuery(NOTICE_DETAILS, {
      fetchPolicy: 'network-only',
      onError() {},
      variables: { where: { id: id } },
    }); */
  return (
    <div className="container">
      <LoaderComponent spinning={loading}>
        <div className="mt-16 mb-16 d-flex justify-between">
          <MyBreadcrumb />
        </div>

        <ProfileDetailCard title="Notices & Orders" data={company?.company} />
        <Card className="mb-24">
          <Collapse
            className="table-collapse"
            defaultActiveKey={['1']}
            items={[
              {
                key: '1',
                label: <Text className="title">Intimations</Text>,
                children: (
                  <TableComponent
                    rowKey=""
                    columns={intimationsColumns as []}
                    dataSource={[] as AdditionalNotice[]}
                    locale={EMPTY_STATE}
                  />
                ),
              },
            ]}
            expandIcon={({ isActive }) => (
              <Button
                icon={!isActive ? <DownArrow /> : <UpArrow />}
                className="justify-center"
              />
            )}
            expandIconPosition="end"
            accordion={false}
          />
        </Card>
        <Card className="mb-24">
          <Collapse
            defaultActiveKey={['1']}
            className="table-collapse"
            items={[
              {
                key: '1',
                label: <Text className="title">Notices</Text>,
                children: (
                  <TableComponent
                    rowKey=""
                    columns={noticesColumns as []}
                    dataSource={[] as AdditionalNotice[]}
                    locale={EMPTY_STATE}
                  />
                ),
              },
            ]}
            expandIcon={({ isActive }) => (
              <Button
                icon={!isActive ? <DownArrow /> : <UpArrow />}
                className="justify-center"
              />
            )}
            expandIconPosition="end"
            accordion={false}
          />
        </Card>
        <Card className="mb-24">
          <Collapse
            defaultActiveKey={['1']}
            className="table-collapse"
            items={[
              {
                key: '1',
                label: <Text className="title">Replies</Text>,
                children: (
                  <TableComponent
                    rowKey=""
                    columns={repliesColumns as []}
                    dataSource={[] as Reply[]}
                    locale={EMPTY_STATE}
                  />
                ),
              },
            ]}
            expandIcon={({ isActive }) => (
              <Button
                icon={!isActive ? <DownArrow /> : <UpArrow />}
                className="justify-center"
              />
            )}
            expandIconPosition="end"
            accordion={false}
          />
        </Card>
        <Card className="mb-24">
          <Collapse
            defaultActiveKey={['1']}
            className="table-collapse"
            items={[
              {
                key: '1',
                label: <Text className="title">Orders</Text>,
                children: (
                  <TableComponent
                    rowKey=""
                    columns={ordersColumns as []}
                    dataSource={[] as AdditionalNotice[]}
                    locale={EMPTY_STATE}
                  />
                ),
              },
            ]}
            expandIcon={({ isActive }) => (
              <Button
                icon={!isActive ? <DownArrow /> : <UpArrow />}
                className="justify-center"
              />
            )}
            expandIconPosition="end"
            accordion={false}
          />
        </Card>
      </LoaderComponent>
    </div>
  );
};

export default NoticesOrdersDetail;
