import { Card, Col, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { GUTTER_VARIATIONS } from '../../../../common/constants';
import { ProfileCardProps } from '../../companies/graphql/clients.type';

dayjs.extend(relativeTime);
const { Text } = Typography;

const ProfileDetailCard = ({ title }: ProfileCardProps) => {
  return (
    <Card className="mb-24 pl-8 pr-8">
      <Text className="heading">{title}</Text>
      <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]} className="mt-16">
        <Col xs={6} xl={6} className="d-grid">
          <Text className="title">Case ID</Text>
          <Text className="value">AD24DF2455432222456</Text>
        </Col>
        <Col xs={6} xl={6} className="d-grid">
          <Text className="title">GSTIN/UIN/Temporary ID</Text>
          <Text className="value">24AAGF1234G2GF</Text>
        </Col>
        <Col xs={6} xl={6} className="d-grid">
          <Text className="title">Date of Application/Case Creation</Text>
          <Text className="value">30-12-2023</Text>
        </Col>
        <Col xs={6} xl={6} className="d-grid">
          <Text className="title">Status</Text>
          <Text className="value">Open</Text>
        </Col>
      </Row>
    </Card>
  );
};

export default ProfileDetailCard;
