import { Button, Card, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { RoundArrow, ThreeLine } from '../../../../assets/svg';
import { GUTTER_VARIATIONS } from '../../../../common/constants';
import LoaderComponent from '../../../../components/LoaderComponent';
import { PlansModalProps } from '../graphql/dashboard.type';

const { Text, Title } = Typography;

const PlansCards = ({
  activePlan,
  plans,
  loading,
  userClient,
}: PlansModalProps) => {
  const redirectPlans = `${process.env.REACT_APP_REDIRECT_PROFILE_URL}/plans`;

  const usedClient = userClient?.directTaxUsedCount ?? 0;
  const totalClient = userClient?.directTaxCount ?? 0;
  const activeOrder = activePlan?.subscriptionPlan?.order ?? 0;
  const expirationDate = dayjs(activePlan?.expireDate);
  const currentDate = dayjs();
  const isExpired = expirationDate.isBefore(currentDate);

  return (
    <>
      <div className="plans-content d-flex align-center justify-center flex-vertical gap-64">
        <div className="plans-text">
          <div className="main-text d-flex justify-center">
            <span className="three-lines-svg">
              <ThreeLine />
            </span>
            <h1 className="text-primaryShade1 h1 semibold">
              Upgrade to more clients
            </h1>
            <span className="round-arrow">
              <RoundArrow />
            </span>
          </div>
          <p className="body-l text-center">
            Automatically grabs and organizes GST notices in one place, keeping
            track of when you need to respond and by what date.
          </p>
        </div>
        <Row
          className="plans-card-content gap-24 justify-center"
          gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}
        >
          {plans?.map((plan, index) => {
            const canDegrade =
              activeOrder > (plan?.order ?? 0) && usedClient > totalClient;
            const isDisabled = activePlan
              ? (activePlan?.subscriptionPlan?.order ?? 1) >=
                  (plan?.order ?? 0) && !isExpired
              : false;
            const maxClientsForPlan = plan?.featureConfig?.assesseeCount ?? 0;

            return (
              <LoaderComponent spinning={loading} key={index}>
                <Card
                  className={`d-flex justify-center flex-vertical ${
                    activeOrder === plan?.order ? 'active-plan' : ''
                  }`}
                >
                  <div className="card gap-24 d-grid text-center justify-center flex-vertical">
                    <Text className="fs-16">{plan?.title}</Text>
                    <span>
                      <Title level={3} className="price m-0">
                        ₹ {plan?.price}
                      </Title>
                      <span className="plans-duration fs-16">
                        {capitalize(plan?.type ?? '')}
                      </span>
                    </span>
                    <Text className="fs-20">
                      {`${plan?.featureConfig?.assesseeCount} Clients`}
                    </Text>
                    {canDegrade && (
                      <p>
                        As per the current plan, you have {totalClient} clients
                        added! Remove{' '}
                        {Math.max(0, usedClient - maxClientsForPlan)} clients to
                        degrade to “{plan?.title}”
                      </p>
                    )}
                    <Button
                      type="primary"
                      onClick={() => window.open(redirectPlans)}
                      disabled={isDisabled}
                    >
                      {(() => {
                        if (
                          activeOrder > 1 &&
                          (plan?.order ?? 0) < activeOrder
                        ) {
                          return `Degrade To ${plan?.title?.split(' ')[0]}`;
                        }
                        if ((plan?.order ?? 0) === activeOrder) {
                          return 'Renew Plan';
                        }
                        return `Upgrade To ${plan?.title?.split(' ')[0]}`;
                      })()}
                    </Button>
                    {activeOrder === plan?.order && (
                      <Button type="default" className="btn-current-plan">
                        Current Plan
                      </Button>
                    )}
                  </div>
                </Card>
              </LoaderComponent>
            );
          })}
        </Row>
      </div>
    </>
  );
};

export default PlansCards;
