import { gql } from '../../../../__generated__';

const GET_TRACK_RETURN_STATUS =
  gql(`query TrackReturnStatusList($filter: TrackReturnStatusFilter!) {
  trackReturnStatusList(filter: $filter) {
      GSTR1 {
        _id
        companyId
        taxPeriod
        financialYear
        dateOfFiling
      }
      GSTR3B {
        _id
        companyId
        taxPeriod
        financialYear
        dateOfFiling
      }
      GSTR9 {
        _id
        companyId
        taxPeriod
        financialYear
        dateOfFiling
      }
      GSTR9C {
        _id
        companyId
        taxPeriod
        financialYear
        dateOfFiling
      }
    }
  }
`);

export { GET_TRACK_RETURN_STATUS };
