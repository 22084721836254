import {
  FileArrowDown,
  FileArrowDownFilled,
  Gavel,
  GavelFilled,
  HomeFilled,
  HomeOutlined,
  Pen,
  PenFiled,
  UsersFilled,
  UsersOutlined,
} from '../../assets/svg';
import { color, MODULES, ROUTES } from '../../common/constants';

type MenuItemType = {
  label: string;
  key: string;
  icon: JSX.Element;
};

export const generateMenuItems = (pathname: string): MenuItemType[] => {
  const directTax = `/${ROUTES.DIRECT_TAX}`;
  const clientRoute = `/${ROUTES?.DIRECT_TAX}/${ROUTES?.CLIENT}`;
  /* kept for future use */
  /*   const filedReturnRoute = `/${ROUTES?.DIRECT_TAX}/${ROUTES?.FILED_RETURN}`;
  const otherFiledRoute = `/${ROUTES?.DIRECT_TAX}/${ROUTES?.OTHER_FILED}`; */
  const taxLitigationRoute = `/${ROUTES?.DIRECT_TAX}/${ROUTES?.TAX_LITIGATION}`;
  if (!pathname.includes(ROUTES.INDIRECT_TAX)) {
    return [
      {
        label: MODULES?.DASHBOARD,
        key: directTax,
        icon:
          pathname === `/${ROUTES?.DIRECT_TAX}` ? (
            <HomeFilled />
          ) : (
            <HomeOutlined />
          ),
      },
      {
        label: MODULES?.CLIENT,
        key: clientRoute,
        icon:
          pathname === clientRoute || pathname.startsWith(clientRoute) ? (
            <UsersFilled />
          ) : (
            <UsersOutlined />
          ),
      },
      // {
      //   label: MODULES?.FILED_RETURN,
      //   key: filedReturnRoute,
      //   icon:
      //     pathname === filedReturnRoute ||
      //     pathname.startsWith(filedReturnRoute) ? (
      //       <FileArrowDownFilled />
      //     ) : (
      //       <FileArrowDown />
      //     ),
      // },
      // {
      //   label: MODULES?.OTHER_FILED,
      //   key: otherFiledRoute,
      //   icon:
      //     pathname === otherFiledRoute ||
      //     pathname.startsWith(otherFiledRoute) ? (
      //       <FileFilled />
      //     ) : (
      //       <FileOutlined />
      //     ),
      // },
      {
        label: MODULES?.TAX_LITIGATION,
        key: taxLitigationRoute,
        icon:
          pathname === taxLitigationRoute ||
          pathname.startsWith(taxLitigationRoute) ? (
            <GavelFilled />
          ) : (
            <Gavel />
          ),
      },
    ];
  }

  const inDirectTax = `/${ROUTES.INDIRECT_TAX}`;
  const inDirectTaxClientRoute = `/${ROUTES?.INDIRECT_TAX}/${ROUTES?.COMPANY}`;
  const inDirectTaxNotice = `/${ROUTES?.INDIRECT_TAX}/${ROUTES?.NOTICE_ORDERS}`;
  const inDirectTaxAdditionalNotice = `/${ROUTES?.INDIRECT_TAX}/${ROUTES?.ADDITIONAL_NOTICE_ORDERS}`;
  const inDirectTaxStatus = `/${ROUTES?.INDIRECT_TAX}/${ROUTES?.TRACK_RETURN_STATUS}`;
  const notice =
    [inDirectTaxNotice, inDirectTaxAdditionalNotice].includes(pathname) ||
    pathname.startsWith(inDirectTaxNotice) ||
    pathname.startsWith(inDirectTaxAdditionalNotice);

  return [
    {
      label: MODULES?.DASHBOARD,
      key: inDirectTax,
      icon:
        pathname === inDirectTax ? (
          <HomeFilled color={color} />
        ) : (
          <HomeOutlined />
        ),
    },
    {
      label: MODULES?.CLIENT,
      key: inDirectTaxClientRoute,
      icon:
        pathname === inDirectTaxClientRoute ||
        pathname.startsWith(inDirectTaxClientRoute) ? (
          <UsersFilled color={color} />
        ) : (
          <UsersOutlined />
        ),
    },
    {
      label: MODULES?.NOTICE_ORDERS,
      key: pathname.startsWith(inDirectTaxNotice)
        ? inDirectTaxNotice
        : inDirectTaxAdditionalNotice,
      icon: notice ? <PenFiled color={color} /> : <Pen />,
    },
    {
      label: MODULES?.TRACK_RETURN_STATUS,
      key: inDirectTaxStatus,
      icon:
        pathname === inDirectTaxStatus ||
        pathname.startsWith(inDirectTaxStatus) ? (
          <FileArrowDownFilled color={color} />
        ) : (
          <FileArrowDown />
        ),
    },
  ];
};
