import { gql } from '../../../../__generated__';

export const CALENDAR_COUNT =
  gql(`query CompanyCalenderCount($filter: CompanyCalenderCountFilter!) {
    companyCalenderCount(filter: $filter) {
      data
      count
      totalIssuedOn
      totalResponseDueCount
      totalCombined
    }
  }`);

export const CURRENT_SYNC_CALENDAR = gql(`query CurrentUserCalender {
  currentUserCalender {
    type
  }
}`);
