import { gql } from '../../../../__generated__';

export const CREATE_COMPANY = gql(`
  mutation CreateCompany($data: CompanyCreateInput!) {
  createCompany(data: $data) {
    message
    data {
      _id
      businessName
      tradeName
      gstNumber
      username
      password
      centerJurisdiction
      stateJurisdiction
      registrationDate
      constitutionOfBusiness
      taxPayerType
      gstStatus
      placeOfBusiness
      proprietor
      authorizedSignature
      natureOfBusinessActivity
      isActive
      createdBy
      createdAt
      updatedAt
    }
  }
}
`);

export const UPDATE_COMPANY = gql(`
  mutation UpdateCompany(
  $where: CompanyUniqueInput!
  $data: CompanyUpdateInput!
) {
  updateCompany(where: $where, data: $data) {
    message
    data {
      _id
      businessName
      tradeName
      gstNumber
      username
      password
      centerJurisdiction
      stateJurisdiction
      registrationDate
      constitutionOfBusiness
      taxPayerType
      gstStatus
      placeOfBusiness
      proprietor
      authorizedSignature
      natureOfBusinessActivity
      isActive
      createdBy
      createdAt
      updatedAt
    }
  }
}
`);

export const DELETE_COMPANY =
  gql(`mutation DeleteCompany($where: CompanyUniqueIdsInput!) {
    deleteCompany(where: $where) {
      message
    }
  }`);

export const SYNC_COMPANY =
  gql(`mutation SyncCompany($where: CompanyUniqueIdsInput!) {
    syncCompany(where: $where) {
      message
    }
  }`);
