import { Col, Divider, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  color,
  COMMON_QUERY_PARAMS,
  defaultDateFormat,
  EMPTY_STATE,
  GUTTER_VARIATIONS,
  NA_PLACEHOLDER_TEXT,
  ROUTES,
} from '../../../../common/constants';
import { AdditionalNoticesDetailProps } from '../graphql/clients.type';
import { EyeIcon } from '../../../../assets/svg';
import useRouter from '../../../../hooks/useRouter';
const { Paragraph, Text } = Typography;

dayjs.extend(relativeTime);

const AdditionalNotices = ({ details }: AdditionalNoticesDetailProps) => {
  const { navigate } = useRouter();
  return (
    <>
      {(details?.length ?? 0) > 0 ? (
        details?.map((detail, index) => (
          <div key={index} className="d-flex flex-vertical gap-16">
            <div className="d-flex gap-24">
              <div className="full-width">
                <Row>
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                      expandable: false,
                    }}
                  >
                    {detail?.description}
                  </Paragraph>
                </Row>
                <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}>
                  <Col span={12} className="d-grid">
                    <Text className="title">ARN</Text>
                    <Text className="value">
                      {detail?.arn || NA_PLACEHOLDER_TEXT}
                    </Text>
                  </Col>
                  <Col span={12} className="d-grid">
                    <Text className="title">Date of Issue of order</Text>
                    <Text className="value text-size-12">
                      {detail?.issuanceDate
                        ? dayjs(detail?.issuanceDate).format(defaultDateFormat)
                        : NA_PLACEHOLDER_TEXT}
                    </Text>
                  </Col>
                </Row>
              </div>
              <div
                className="eye-action pointer"
                onClick={() =>
                  navigate(
                    `${ROUTES?.ADDITIONAL_NOTICE_ORDERS}/${ROUTES?.DETAIL}?${COMMON_QUERY_PARAMS.NOTICE_ID}=${detail?._id}`,
                  )
                }
              >
                <EyeIcon color={color} />
              </div>
            </div>
            {index < details.length - 1 && <Divider className="mb-16 mt-16" />}
          </div>
        ))
      ) : (
        <p className="empty text-center">{EMPTY_STATE?.emptyText}</p>
      )}
    </>
  );
};

export default AdditionalNotices;
