import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
} from 'antd';
import { ColumnType, TableProps } from 'antd/es/table';
import dayjs from 'dayjs';
import { isEmpty, omit } from 'lodash';
import { Key, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  AdditionalNotice,
  AdditionalNoticesSort,
  AdditionalNoticesSortOn,
  AdditionalNoticesType,
  DurationType,
  NoticeAndOrderStatus,
  NoticeAndOrderType,
  SortOrder,
} from '../../../../__generated__/graphql';
import {
  Export,
  EyeIcon,
  FilePDF,
  Icon,
  Minus,
  Pencil,
  SelectDropdownIcon,
} from '../../../../assets/svg';
import axiosInstance from '../../../../common/axiosInstance';
import {
  ADDITIONAL_NOTICE_STATUS_OPTIONS,
  ADDITIONAL_NOTICE_TYPE,
  AI_TOOLTIP_MESSAGE,
  color,
  COMMON_QUERY_PARAMS,
  defaultDateFormat,
  DOWNLOAD_TEMPLATE_LINK,
  DURATION_TYPE,
  EMPTY_STATE,
  LIMIT,
  MESSAGE,
  ROUTES,
} from '../../../../common/constants';
import useQueryValue from '../../../../common/hooks/useQueryValue';
import { downloadCsv, formValidatorRules } from '../../../../common/utils';
import CommonPagination from '../../../../components/CommonPagination';
import CommonSearch from '../../../../components/CommonSearch';
import TableComponent from '../../../../components/CommonTable';
import StatusTag from '../../../../components/CommonTag';
import InfiniteSelect from '../../../../components/InfiniteSelect';
import CommonModal from '../../../../components/modals/CommonModal';
import useRouter from '../../../../hooks/useRouter';
import {
  FormValues,
  SelectedRowKeysState,
} from '../../../../types/common.type';
import { COMPANIES } from '../../companies/graphql/Queries';
import { UPDATE_NOTICE_ORDER_STATUS_BULK } from '../graphql/Mutations';
import { ADDITIONAL_NOTICES_ORDERS_LIST } from '../graphql/Queries';
const { RangePicker } = DatePicker;
const { required } = formValidatorRules;

const initialFilters = {
  search: '',
  skip: 0,
  limit: LIMIT,
};

const fieldToParams: Record<string, string> = {
  issuanceDate: COMMON_QUERY_PARAMS.SORT_ISSUANCE_DATE,
};

const NoticeOrder = () => {
  const [filterForm] = Form.useForm();
  const [statusForm] = Form?.useForm();
  const { navigate } = useRouter();
  const [searchParams] = useSearchParams();
  const urlDataObj = useQueryValue();
  const currentPage = urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]
    ? Number(urlDataObj?.[COMMON_QUERY_PARAMS.PAGE])
    : 1;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<SelectedRowKeysState>(
    {},
  );
  const updateBulkStatus = Form.useWatch('statusForm', statusForm);
  const askAI = process.env.REACT_PUBLIC_ASK_AI_REDIRECT_URL!;
  const totalSelectedRows = Object?.values(selectedRowKeys)?.flat();

  const cancelModal = () => {
    setIsModalOpen(false);
    statusForm.resetFields();
  };
  const initialValue = {
    filters: {
      companyId: urlDataObj?.[COMMON_QUERY_PARAMS.COMPANY_ID] || null,
      duration:
        (urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] as DurationType) || null,
      ids: [],
      isIssuedOn:
        urlDataObj?.[COMMON_QUERY_PARAMS.ISSUED_ON] &&
        urlDataObj?.[COMMON_QUERY_PARAMS.ISSUED_ON] === 'true'
          ? true
          : false,
      type: (urlDataObj?.[COMMON_QUERY_PARAMS.NOTICE_TYPE] ||
        null) as AdditionalNoticesType,
      ...(urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] ===
        DurationType.Custom && {
        period: [
          urlDataObj?.[COMMON_QUERY_PARAMS.START]
            ? dayjs(
                urlDataObj?.[COMMON_QUERY_PARAMS.START],
                defaultDateFormat,
              ).startOf('day')
            : '',
          urlDataObj?.[COMMON_QUERY_PARAMS.END]
            ? dayjs(
                urlDataObj?.[COMMON_QUERY_PARAMS.END],
                defaultDateFormat,
              ).endOf('day')
            : '',
        ],
      }),
      limit: Number(urlDataObj?.[COMMON_QUERY_PARAMS.LIMIT]) || LIMIT,
      skip: urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]
        ? (Number(urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]) - 1) * LIMIT
        : 0,
      status:
        (urlDataObj?.[COMMON_QUERY_PARAMS.STATUS] as NoticeAndOrderStatus) ||
        null,
      search:
        (urlDataObj?.[COMMON_QUERY_PARAMS.SEARCH] as NoticeAndOrderType) || '',
      section: urlDataObj?.[COMMON_QUERY_PARAMS.SECTION] || '',
    },
  };
  const filters = initialValue.filters;
  filterForm.setFieldsValue({ filters: filters });
  const prepareQueryVariableFromUrl = useMemo(() => {
    let prepareSorterFromURL: {
      sortBy: SortOrder;
      sortOn: AdditionalNoticesSortOn;
    }[] = [];
    Object.keys(fieldToParams).forEach((item) => {
      if (searchParams.has(fieldToParams?.[item as string])) {
        const value = searchParams.get(fieldToParams?.[item as string]);
        prepareSorterFromURL.push({
          sortBy: value as SortOrder,
          sortOn: item as AdditionalNoticesSortOn,
        });
      }
    });

    if (prepareSorterFromURL.length === 0) {
      prepareSorterFromURL = [
        { sortBy: SortOrder.Desc, sortOn: AdditionalNoticesSortOn.CreatedAt },
      ];
    }

    return {
      filter: {
        companyId: urlDataObj?.[COMMON_QUERY_PARAMS.COMPANY_ID] || null,
        duration:
          (urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] as DurationType) || null,
        ids: [],
        isIssuedOn:
          urlDataObj?.[COMMON_QUERY_PARAMS.ISSUED_ON] &&
          urlDataObj?.[COMMON_QUERY_PARAMS.ISSUED_ON] === 'true'
            ? true
            : false,
        type:
          urlDataObj?.[COMMON_QUERY_PARAMS.NOTICE_TYPE] ||
          AdditionalNoticesType.All,
        ...(urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] ===
          DurationType.Custom && {
          period: {
            start: urlDataObj?.[COMMON_QUERY_PARAMS.START]
              ? dayjs(
                  urlDataObj?.[COMMON_QUERY_PARAMS.START],
                  defaultDateFormat,
                ).startOf('day')
              : '',
            end: urlDataObj?.[COMMON_QUERY_PARAMS.END]
              ? dayjs(
                  urlDataObj?.[COMMON_QUERY_PARAMS.END],
                  defaultDateFormat,
                ).endOf('day')
              : '',
          },
        }),
        limit: Number(urlDataObj?.[COMMON_QUERY_PARAMS.LIMIT]) || LIMIT,
        skip: urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]
          ? (Number(urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]) - 1) * LIMIT
          : 0,
        status:
          (urlDataObj?.[COMMON_QUERY_PARAMS.STATUS] as NoticeAndOrderStatus) ||
          null,
        search: urlDataObj?.[COMMON_QUERY_PARAMS.SEARCH] || '',
      },
      sort: prepareSorterFromURL as AdditionalNoticesSort[],
    };
  }, [location.search]);

  const handlePagination = (page: number) => {
    searchParams.set(COMMON_QUERY_PARAMS.PAGE, page.toString());
    searchParams.set(COMMON_QUERY_PARAMS.LIMIT, LIMIT.toString());
    navigate(`${location?.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const resetPagination = () => {
    searchParams.delete(COMMON_QUERY_PARAMS.PAGE);
    searchParams.delete(COMMON_QUERY_PARAMS.LIMIT);
  };

  /* any used due to type incompatibility */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDateRangeNavigation = (date: any) => {
    if (date && date.length > 0) {
      const startDate = dayjs(date[0]).format(defaultDateFormat);
      const endDate = dayjs(date[1]).format(defaultDateFormat);
      searchParams.set(COMMON_QUERY_PARAMS.START, startDate);
      searchParams.set(COMMON_QUERY_PARAMS.END, endDate);
    } else {
      if (
        searchParams.has(COMMON_QUERY_PARAMS.START) ||
        searchParams.has(COMMON_QUERY_PARAMS.END)
      ) {
        searchParams.delete(COMMON_QUERY_PARAMS.START);
        searchParams.delete(COMMON_QUERY_PARAMS.END);
      }
    }
    resetPagination();
    navigate(`${location?.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const handleClear = () => {
    navigate(`${location?.pathname}`, {
      replace: true,
    });
  };

  const {
    data: { additionalNotices = {} } = {},
    loading,
    refetch,
  } = useQuery(ADDITIONAL_NOTICES_ORDERS_LIST, {
    variables: prepareQueryVariableFromUrl,
    fetchPolicy: 'network-only',
    onError() {},
  });

  const rowSelection: TableProps<AdditionalNotice>['rowSelection'] = {
    selectedRowKeys: selectedRowKeys?.[currentPage],
    onChange: (newSelectedRowKeys: Key[]) => {
      setSelectedRowKeys((prev) => ({
        ...prev,
        [currentPage]: newSelectedRowKeys,
      }));
    },
    ...(selectedRowKeys[currentPage]?.length && {
      columnTitle: () => (
        <span
          className="d-flex pointer"
          onClick={() => {
            setSelectedRowKeys((prev) => ({
              ...prev,
              [currentPage]: [],
            }));
          }}
        >
          <Minus />
        </span>
      ),
    }),
  };

  const handleFieldsNavigation = (fieldType: string, value: string | null) => {
    resetPagination();
    if (value) {
      searchParams.set(fieldType, value as string);
    } else {
      if (searchParams.has(fieldType)) {
        searchParams.delete(fieldType);
      }
    }
    navigate(`${location?.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const exportCsv = async () => {
    const response = await axiosInstance.post('/v1/eproceeding/export-csv', {
      filter: {
        ...omit(prepareQueryVariableFromUrl.filter, ['limit', 'skip']),
        ids: totalSelectedRows,
      },
    });
    downloadCsv(response);
    setSelectedRowKeys(() => ({}));
  };

  const handleDurationNavigation = (e: string) => {
    if (
      searchParams.has(COMMON_QUERY_PARAMS.START) ||
      searchParams.has(COMMON_QUERY_PARAMS.END)
    ) {
      searchParams.delete(COMMON_QUERY_PARAMS.START);
      searchParams.delete(COMMON_QUERY_PARAMS.END);
    }
    resetPagination();
    handleFieldsNavigation(COMMON_QUERY_PARAMS.DURATION, e);
  };

  const [updateStatus, { loading: updateStatusLoading }] = useMutation(
    UPDATE_NOTICE_ORDER_STATUS_BULK,
    {
      onCompleted: () => {
        refetch(prepareQueryVariableFromUrl);
        statusForm.resetFields();
        setIsModalOpen(false);
        setSelectedRowKeys(() => ({}));
      },
    },
  );

  const updateStateForm = (value: FormValues) => {
    updateStatus({
      variables: {
        where: {
          ids: value?.statusForm?.id,
        },
        data: {
          status: value?.statusForm?.status,
        },
      },
    });
  };

  const columns: ColumnType<AdditionalNotice>[] = [
    {
      title: 'GSTIN',
      dataIndex: 'gstNumber',
      key: '_id',
      fixed: 'left',
      render: (_, record) => {
        return (
          <a
            className="m-0 text"
            onClick={() =>
              navigate(
                `/${ROUTES.INDIRECT_TAX}/${ROUTES.NOTICE_ORDERS}/${ROUTES?.DETAIL}?${COMMON_QUERY_PARAMS.NOTICE_ID}=${record._id}`,
              )
            }
          >
            {record?.company?.gstNumber}
          </a>
        );
      },
    },
    {
      title: 'Trade Name',
      dataIndex: 'tradeName',
      key: 'tradeName',
      fixed: 'left',
      render: (_, record) => {
        return record?.company?.tradeName;
      },
    },
    {
      title: 'Section',
      dataIndex: 'sectionNo',
      key: 'sectionNo',
      fixed: 'left',
      render: (text) => {
        return text ?? '-';
      },
    },
    {
      title: 'Reference ID',
      dataIndex: 'refId',
      key: 'refId',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Issued Date',
      dataIndex: 'issuanceDate',
      key: '_id',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
    {
      title: 'Due Date',
      dataIndex: 'replyDueDate',
      key: '_id',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
    {
      title: 'Reply Filled Against',
      dataIndex: 'repliedAgainstFiled',
      key: '_id',
      render: (_, item) =>
        item?.replies && item?.replies?.length > 0
          ? item?.replies?.[0]?.repliedAgainstFiled
          : '-',
    },
    {
      title: 'Reply Date',
      dataIndex: 'replyDueDate',
      key: '_id',
      render: (_, item) =>
        item?.replies && item?.replies?.length > 0
          ? dayjs(item?.replies?.[0]?.replyDate).format(defaultDateFormat)
          : '-',
    },
    {
      title: 'Order No.',
      key: '_id',
      render: (_, item) => (item?.section === 'ORDER' ? item?.refId : '-'),
    },
    {
      title: 'Order Date',
      key: '_id',
      render: (_, item) =>
        item?.section === 'ORDER'
          ? dayjs(item.issuanceDate).format(defaultDateFormat)
          : '-',
    },
    /* kept for future use */
    /*  {
      title: 'Submission Amount',
      dataIndex: 'issuanceDate',
      key: '_id',
      render: (amount: string) => (amount ? amount : '-'),
    },
    {
      title: 'Submission Date',
      dataIndex: 'issuanceDate',
      key: '_id',
      render: (amount: string) => (amount ? amount : '-'),
    }, */
    /*
    {
      title: 'Remarks',
      dataIndex: 'description',
      key: 'description',
    }, */
    {
      title: 'Notice/Order Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Notice Type',
      dataIndex: 'section',
      key: '_id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      fixed: 'right',
      key: 'status',
      render: (status) => <StatusTag status={status} />,
    },
    {
      title: 'Action',
      fixed: 'right',
      render: (record) => (
        <Space>
          <Link
            className="pointer"
            to={record?.attachments?.[0]?.url ?? ''}
            download={record?.attachments?.[0]?.url}
            target="_blank"
          >
            <FilePDF />
          </Link>
          <span
            key="edit"
            onClick={() => {
              setIsModalOpen(true);
              statusForm.setFieldsValue({
                statusForm: {
                  status: record.status,
                  id: record._id,
                },
              });
            }}
            className="pointer"
          >
            <Pencil color={color} />
          </span>
          <span className="pointer">
            <EyeIcon key="view" color={color} />
          </span>
          <Tooltip placement="top" title={AI_TOOLTIP_MESSAGE}>
            <Button
              type="default"
              icon={<Icon color={color} />}
              className="d-flex align-center ask-ai-btn pointer p-0"
              onClick={() => navigate(askAI)}
            >
              Ask AI
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <div className="full-width">
      <div className="button-container">
        <div className="left-buttons gap-16">
          <Button
            type="default"
            onClick={async () => {
              setIsModalOpen(true);
              statusForm.setFieldsValue({
                statusForm: {
                  id: totalSelectedRows,
                },
              });
            }}
            disabled={!(totalSelectedRows.length > 0)}
          >
            Bulk Update Status
          </Button>
          <Link to={DOWNLOAD_TEMPLATE_LINK}>
            <Button
              type="default"
              icon={<Export color="#04724D" />}
              onClick={() => exportCsv()}
            >
              Export To CSV
            </Button>
          </Link>
        </div>
        <div className="right-buttons gap-16">
          <CommonSearch
            handleChangeSearch={(val) => {
              handleFieldsNavigation(COMMON_QUERY_PARAMS.SEARCH, val?.trim());
            }}
            iconPlacement="right"
            allowClear
            defaultValue={prepareQueryVariableFromUrl?.filter?.search || ''}
          />
        </div>
      </div>
      <Card className="table-card mt-16">
        <div className="d-flex flex-vertical gap-16">
          <Form
            form={filterForm}
            layout="vertical"
            initialValues={initialFilters}
          >
            <div className="filters d-flex align-center gap-8">
              <Form.Item
                name={['filters', 'companyId']}
                label="Select Client"
                className="select"
              >
                <InfiniteSelect
                  query={COMPANIES}
                  fetchPolicy="network-only"
                  variableSelector={({ skip, limit, search }) => ({
                    filter: {
                      skip,
                      limit,
                      search,
                    },
                    sort: {
                      sortBy: SortOrder.Desc,
                    },
                  })}
                  dataSelector={({ companies }) => {
                    return (
                      companies?.data?.map((item) => ({
                        label: `${item?.gstNumber} | ${item?.tradeName}`,
                        value: item?._id ?? '',
                      })) ?? []
                    );
                  }}
                  countSelector={({ companies }) => companies?.count ?? 0}
                  allowSearch
                  placeholder="GSTIN, tradename"
                  allowClear
                  /* any used to onchange value type incompatibility */
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) =>
                    handleFieldsNavigation(
                      COMMON_QUERY_PARAMS.COMPANY_ID,
                      e && (e.value as string),
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'type']}
                label="Type"
                className="select"
              >
                <Select
                  suffixIcon={<SelectDropdownIcon />}
                  placeholder="All"
                  options={ADDITIONAL_NOTICE_TYPE}
                  onChange={(e: string) =>
                    handleFieldsNavigation(
                      COMMON_QUERY_PARAMS.NOTICE_TYPE,
                      e ?? null,
                    )
                  }
                  allowClear
                  showSearch
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'status']}
                label="Status"
                className="select"
              >
                <Select
                  placeholder="All"
                  options={ADDITIONAL_NOTICE_STATUS_OPTIONS}
                  allowClear
                  showSearch
                  suffixIcon={<SelectDropdownIcon />}
                  onChange={(e: string) =>
                    handleFieldsNavigation(
                      COMMON_QUERY_PARAMS.STATUS,
                      e ?? null,
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'duration']}
                label="Duration"
                className="select"
              >
                <Select
                  suffixIcon={<SelectDropdownIcon />}
                  placeholder="All"
                  options={DURATION_TYPE}
                  onChange={(e: string) => handleDurationNavigation(e)}
                  allowClear
                />
              </Form.Item>
              {filters?.duration === DurationType.Custom && (
                <Form.Item
                  name={['filters', 'period']}
                  label="Period"
                  className="select"
                >
                  <RangePicker
                    allowClear
                    onChange={(date) => handleDateRangeNavigation(date)}
                    format={defaultDateFormat}
                  />
                </Form.Item>
              )}
              <Form.Item className="d-flex align-end align-self-end">
                <Button
                  type="link"
                  onClick={handleClear}
                  disabled={
                    !(
                      filters.companyId ||
                      filters.status ||
                      filters.duration ||
                      filters.search
                    )
                  }
                >
                  Clear All
                </Button>
              </Form.Item>
            </div>
          </Form>
          <TableComponent
            columns={columns}
            dataSource={additionalNotices?.data as AdditionalNotice[]}
            pagination={false}
            rowSelection={rowSelection}
            rowKey="_id"
            scroll={{ x: 'max-content' }}
            locale={EMPTY_STATE}
            loading={loading}
          />
        </div>
        {additionalNotices?.count ? (
          <CommonPagination
            count={additionalNotices?.count}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        ) : null}
      </Card>
      <CommonModal
        open={isModalOpen}
        title="Update Status"
        footer={false}
        closable={true}
        onCancel={cancelModal}
        maskClosable={false}
      >
        <div className="create-forms-form">
          <Form
            onFinish={updateStateForm}
            form={statusForm}
            layout="vertical"
            preserve={false}
          >
            <Form.Item
              label="Status"
              name={['statusForm', 'status']}
              rules={[
                { ...required, message: MESSAGE?.required, whitespace: true },
              ]}
            >
              <Select
                suffixIcon={<SelectDropdownIcon />}
                options={ADDITIONAL_NOTICE_STATUS_OPTIONS}
                placeholder="Change Status"
              />
            </Form.Item>
            <Form.Item
              label="Remark"
              name={['statusForm', 'remark']}
              rules={[{ whitespace: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Remark" name={['statusForm', 'id']} hidden>
              <Select suffixIcon={<SelectDropdownIcon />} mode="multiple" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="full-width"
              loading={updateStatusLoading}
              disabled={isEmpty(updateBulkStatus?.status)}
            >
              Update
            </Button>
          </Form>
        </div>
      </CommonModal>
    </div>
  );
};

export default NoticeOrder;
