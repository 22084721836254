import { ConfigProvider, Modal } from 'antd';
import { LogoutWarningIcon } from '../assets/svg';
import { directTaxThemeConfig } from '../styles/directTaxThemeConfig';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function LogoutPopup({ errorElement }: { errorElement: any }) {
  const handleLogout: () => void = () => {
    const redirectProfile = `${process.env.REACT_APP_REDIRECT_PROFILE_URL}`;
    window.location.href = redirectProfile;
    errorElement.unmount();
  };

  return (
    <ConfigProvider theme={directTaxThemeConfig}>
      <Modal
        open
        width="512px"
        onOk={() => handleLogout()}
        closeIcon={null}
        footer={() => (
          <button onClick={() => handleLogout()} className="logout-popup-btn">
            Continue
          </button>
        )}
      >
        <div className="d-flex flex-vertical align-center gap-8">
          <LogoutWarningIcon />
          <div className="body-xl semibold text-center">
            You have been logged out because you have signed in from another
            device
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
}

export default LogoutPopup;
