import { Card, Typography } from 'antd';
import { Company } from '../../../../__generated__/graphql';
import { Proprietor } from '../graphql/clients.type';
import { NA_PLACEHOLDER_TEXT } from '../../../../common/constants';

const { Text } = Typography;

const CardList = ({ cards }: { cards: Company }) => {
  return (
    <Card className="full-width">
      <Text className="heading">Other Details</Text>
      <div className="d-flex gap-16">
        <Card key={cards?._id} className="width-percent-25">
          <div className="d-flex flex-vertical gap-16">
            <span>
              <Text className="title fs-16">Business Owners</Text>
            </span>
            <span>
              {Array.isArray(cards.proprietor) ? (
                cards.proprietor.map((owner: Proprietor, index: number) => (
                  <div key={index} className="d-flex flex-vertical">
                    <Text className="value fs-16">
                      {owner?.name || NA_PLACEHOLDER_TEXT}
                    </Text>
                  </div>
                ))
              ) : (
                <Text className="value fs-16">No data available</Text>
              )}
            </span>
          </div>
        </Card>
        <Card className="width-percent-25">
          <div className="d-flex flex-vertical gap-16">
            <Text className="title fs-16">HSN / SAC</Text>
            <Text className="value fs-16">{NA_PLACEHOLDER_TEXT}</Text>
          </div>
        </Card>
        <Card className="width-percent-25">
          <div className="d-flex flex-vertical gap-16">
            <Text className="title fs-16">Return Periodicity</Text>
            <div className="d-flex  gap-16">
              <span className="d-flex flex-vertical">
                <Text className="value fs-16">2024-2025</Text>
                <Text className="value fs-16">Q1 Monthly</Text>
                <Text className="value fs-16">Q2 Monthly</Text>
                <Text className="value fs-16">Q3 Monthly</Text>
              </span>
              <span className="d-flex flex-vertical">
                <Text className="value fs-16">2024-2025</Text>
                <Text className="value fs-16">Q1 Monthly</Text>
                <Text className="value fs-16">Q2 Monthly</Text>
                <Text className="value fs-16">Q3 Monthly</Text>
              </span>
            </div>
          </div>
        </Card>
        <Card className="width-percent-25">
          <div className="d-flex flex-vertical gap-16">
            <span>
              <Text className="title fs-16">Business Activities</Text>
            </span>
            <span>
              {cards?.natureOfBusinessActivity?.map(
                (activity: string, index: number) => (
                  <Text key={index} className="value fs-16 d-flex">
                    {activity}
                  </Text>
                ),
              )}
            </span>
          </div>
        </Card>
      </div>
    </Card>
  );
};

export default CardList;
