export const directTaxThemeConfig = {
  token: {
    colorPrimary: '#7a2976',
    radiusBase: '4px',
    controlRadius: '4px',
    colorLink: '#7a2976',
    colorLinkActive: '#7a2976',
    colorLinkHover: '#7a2976',
    colorBgLayout: 'var(--background-2)',
    fontFamily: 'var(--font-family)',
  },
  components: {
    Typography: {},
    Button: {
      borderRadius: 8,
      colorPrimary: '#7a2976',
      colorPrimaryHover: '#7a2976',
      colorPrimaryActive: '#7a2976',
      colorLink: '#7a2976',
      colorLinkHover: '#7a2976',
      colorLinkActive: '#7a2976',
      defaultColor: '#7a2976',
      defaultBorderColor: '#7a2976',
      primaryShadow: 'unset',
      colorTextDisabled: '#F4F4F4',
      colorBgContainerDisabled: '#7a2976',
      borderColorDisabled: '#7a2976',
      colorText: 'var(--primary)',
      controlHeight: 40,
      fontSize: 16,
    },
    Menu: { itemSelectedBg: 'var(--primary-Tint-02)' },
    Table: {
      headerBg: 'var(--color-grey-light)',
      headerColor: 'var(--text-medium)',
      fontWeightStrong: 500,
      rowSelectedBg: '#FBF9FB',
      rowSelectedHoverBg: '#FBF9FB',
    },
    Form: {
      labelFontSize: 16,
      itemMarginBottom: 16,
    },
    Breadcrumb: {
      fontSize: 12,
    },
    Input: {
      activeShadow: 'unset',
      controlHeight: 40,
      colorTextPlaceholder: 'var(--text-medium)',
    },
    Select: {
      controlOutlineWidth: 0,
      optionSelectedBg: '#FBF9FB',
      controlHeight: 40,
    },
    DatePicker: {
      activeShadow: 'unset',
      controlHeight: 40,
    },
    Tag: {
      lineWidth: 0,
    },
    Badge: {
      fontSize: 12,
    },
    Modal: {
      colorBgMask: 'rgba(0, 0, 0, 0.70)',
      titleFontSize: 20,
    },
    Checkbox: {
      colorPrimary: 'var(--text-medium)',
      colorPrimaryHover: 'var(--text-medium)',
      colorPrimaryBorder: 'var(--text-medium)',
    },
    Tooltip: {
      colorBgSpotlight: '#001626',
    },
  },
};
