import { Tag, Tooltip } from 'antd';
import React from 'react';

type StatusTagProps = {
  status: string;
};

const StatusTag: React.FC<StatusTagProps> = ({ status }) => {
  let className = 'status-default';

  switch (status) {
    case 'Verified':
      className = 'status-form-verified';
      break;
    case 'For Verification':
      className = 'status-for-verification';
      break;
    case 'FAILED':
      className = 'status-failed';
      break;
    case 'SUCCESS':
      className = 'status-success';
      break;
    case 'PENDING':
      className = 'status-pending';
      break;
    case 'CLOSED':
      className = 'status-closed';
      break;
    case 'REPLIED':
      className = 'status-replied';
      break;
    case 'OPEN':
      className = 'status-replied';
      break;
    case 'CLOSE':
      className = 'status-closed';
      break;
    default:
      className = 'status-default';
  }

  return (
    <Tooltip title={status}>
      <Tag className={`${className} capitalize-first`} key={status}>
        {status}
      </Tag>
    </Tooltip>
  );
};

export default StatusTag;
