import { Card, Divider } from 'antd';
import {
  DurationType,
  EProceedingStatus,
  NoticeAndOrderStatus,
} from '../../__generated__/graphql';
import {
  INDIRECT_TAX,
  ROUTES,
  ZERO_PLACEHOLDER_TEXT,
} from '../../common/constants';
import useRouter from '../../hooks/useRouter';
import { StatisticData } from '../../types/common.type';
import StatisticCard from '../StatiscticCard';

const Overview = ({ statisticData }: { statisticData: StatisticData[] }) => {
  const { navigate, location } = useRouter();

  const navigateToListPage = (items: string) => {
    if (location.pathname.includes(INDIRECT_TAX)) {
      ['failedLogin', 'allPan'].includes(items ?? '')
        ? navigate(`${ROUTES.COMPANY}?company=${items}`)
        : navigate(
            `${ROUTES.NOTICE_ORDERS}?duration=${items}${
              items !== DurationType?.Last_24Hours &&
              items !== DurationType?.All
                ? `&status=${NoticeAndOrderStatus?.Open}`
                : ''
            }`,
          );
    } else {
      ['failedLogin', 'allPan'].includes(items ?? '')
        ? navigate(`/${ROUTES.DIRECT_TAX}/${ROUTES.CLIENT}?duration=${items}`)
        : navigate(
            `/${ROUTES.DIRECT_TAX}/${ROUTES.TAX_LITIGATION}?duration=${items}${
              items !== DurationType?.Last_24Hours &&
              items !== DurationType?.All
                ? `&status=${EProceedingStatus?.Pending}`
                : ''
            }`,
          );
    }
  };

  return (
    <Card className="mb-16">
      <div className="statistic-cards">
        {statisticData.map((items, i) => {
          return (
            <>
              <StatisticCard
                key={items?.label}
                icon={items?.icon}
                label={items?.label}
                value={items?.value || ZERO_PLACEHOLDER_TEXT}
                onClick={() => navigateToListPage(items?.key || '')}
              />
              {i === 3 && statisticData.length >= 8 && <Divider />}
            </>
          );
        })}
      </div>
    </Card>
  );
};

export default Overview;
