import { gql } from '../../../../__generated__';

export const ASSESSEES =
  gql(`query Assessees($sort: [AssesseesSort]!, $filter: AssesseesFilter) {
  assessees(sort: $sort, filter: $filter) {
    count
    data {
      _id
      name
      phoneNumber
      loginStatus
      username
      password
      isActive
      createdBy
      incorporateDate
      address
      jurisdictionDetails
      createdAt
      updatedAt
      lastSyncAt
      activityLogs {
        _id
        portal
        pan
        status
        message
        createdAt
        updatedAt
      }
    }
  }
}`);

export const SYNC_LOG =
  gql(`query SyncLogsData($filter: SyncLogsFilter!, $sort: [SyncLogsSort]) {
  syncLogs(filter: $filter, sort: $sort) {
    count
    data {
      _id
      portal
      pan
      status
      message
      createdAt
      updatedAt
      assessee {
        _id
        name
        phoneNumber
        loginStatus
        username
        password
        isActive
        createdBy
        incorporateDate
        address
        jurisdictionDetails
        createdAt
        updatedAt
        lastSyncAt
      }
    }
  }
}`);

export const ASSESSEE = gql(`query Assessee($where: AssesseeUniqueInput!) {
  assessee(where: $where) {
    _id
    name
    phoneNumber
    loginStatus
    username
    password
    isActive
    createdBy
    incorporateDate
    address
    createdAt
    updatedAt
    lastSyncAt
    jurisdictionDetails
    activityLogs {
      _id
      portal
      pan
      status
      message
      createdAt
      updatedAt
    }
  }
}`);

export const CLIENTS =
  gql(`query Clients($sort: [AssesseesSort]!, $filter: AssesseesFilter) {
    assessees(sort: $sort, filter: $filter) {
      data {
        _id
        username
        name
      }
      count
    }
  }`);

export const E_PROCEEDING =
  gql(`query EProceedings($filter: EProceedingsFilter,$sort: [EProceedingsSort]) {
    eProceedings(filter: $filter, sort: $sort) {
      count
      data {
        _id
        name
        assessmentYear
        financialYear
        issuedOn
        responseDueDate
        proceedingCloseDate
        proceedingCloseOrder
        proceedingLimitationDate
        noticeUs
        noticeReferenceId
        documentRefId
        description
        proceedingOpenDate
        type
        remarks
        remarkByAdmin
        itrType
        status
        createdAt
        updatedAt
        responses {
        _id
        responseDate
        remarks
        submittedOn
        filedBy
        attachments {
          name
          url
        }
        createdAt
        updatedAt
      }
        assessee {
          lastSyncAt
          _id
          username
          name
        }
      }
    }
  }`);

export const INCOME_TAX_OTHER_FORM =
  gql(`query ItfList($sort: [IncomeTaxFormsSort], $filter: IncomeTaxFormsFilter) {
  itfList(sort: $sort, filter: $filter) {
    count
    data {
      _id
      acknowledgmentNo
      assessmentYear
      filingDate
    }
  }
}
`);

export const INCOME_TAX_RETURN_FORM =
  gql(`query ItrList($sort: [ItrListSort], $filter: ItrListFilter) {
  itrList(sort: $sort, filter: $filter) {
    count
    data {
      _id
      assessmentYear
      acknowledgmentNo
      filingDate
    }
  }
}
`);
