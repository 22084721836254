import { useLazyQuery } from '@apollo/client';
import { Card, DatePicker, Form, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  InputMaybe,
  SortOrder,
  TrackReturnStatus,
  TrackReturnStatusFilter,
  TrackReturnStatusListResponse,
} from '../../../__generated__/graphql';
import {
  defaultDateFormat,
  EMPTY_STATE,
  LIMIT,
} from '../../../common/constants';
import TableComponent from '../../../components/CommonTable';
import InfiniteSelect from '../../../components/InfiniteSelect';
import MyBreadcrumb from '../../../components/MyBreadcrumb';
import { GET_TRACK_RETURN_STATUS } from './graphql/Queries';
import { COMPANIES } from '../companies/graphql/Queries';
import LoaderComponent from '../../../components/LoaderComponent';
const { Text } = Typography;
const { RangePicker } = DatePicker;

const initialFilters = {
  financialYear: null,
  companyId: null,
  skip: 0,
  limit: LIMIT,
};

const initialValue = {
  filters: {
    assesseeId: null,
    financialYear: null,
    duration: {
      end: null,
      start: null,
    },
  },
};

const TrackReturnsStatus = () => {
  const [filterForm] = Form.useForm();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<TrackReturnStatusListResponse | null>(null);
  const filters = Form.useWatch('filters', filterForm) ?? initialValue.filters;
  const [searchParams] = useSearchParams();

  const id = searchParams.get('id');
  const GSTNumber = searchParams.get('username');
  const tradeName = searchParams.get('tradeName');

  const filter: InputMaybe<TrackReturnStatusFilter> = useMemo(
    () => ({
      financialYear: filters?.financialYear
        ? `${dayjs(filters?.financialYear[0])?.year()}-${dayjs(filters?.financialYear[1])?.year()}`
        : undefined,
      companyId: filters?.companyId?.value ?? id,
      skip: (currentPage - 1) * LIMIT,
      limit: filters.limit,
    }),
    [filters, id],
  );

  const [getTrackReturnStatus, { loading }] = useLazyQuery(
    GET_TRACK_RETURN_STATUS,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted: (response) => {
        if (response.trackReturnStatusList) {
          setData(response?.trackReturnStatusList);
        }
      },
    },
  );

  useEffect(() => {
    if (filter.companyId) {
      getTrackReturnStatus({
        variables: {
          filter: filter,
        },
      });
    }

    if (id) {
      filterForm.setFieldsValue({
        filters: {
          companyId: {
            label: `${GSTNumber} | ${tradeName}`,
            value: id ?? '',
          },
        },
      });
    }
  }, [filter]);

  const columns: ColumnType<TrackReturnStatus>[] = [
    {
      title: 'Financial Year',
      dataIndex: 'financialYear',
      key: 'financialYear',
      render: (text) => text ?? '-',
    },
    {
      title: 'Period',
      dataIndex: 'taxPeriod',
      key: 'taxPeriod',
      render: (text) => text ?? '-',
    },
    {
      title: 'Filing Date',
      dataIndex: 'dateOfFiling',
      key: 'dateOfFiling',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
  ];

  return (
    <div className="container">
      <div className="mt-16 mb-16">
        <MyBreadcrumb />
      </div>
      <div className="d-flex flex-vertical gap-16">
        <Text className="heading">Track Return Status</Text>
        <Card className="gap-16">
          <div className="track-return-status d-flex flex-vertical gap-16">
            <Form
              form={filterForm}
              layout="vertical"
              onValuesChange={() => setCurrentPage(1)}
              initialValues={initialFilters}
            >
              <div className="filters d-flex align-center gap-24">
                <Form.Item
                  name={['filters', 'companyId']}
                  label="Select Client"
                  className="select"
                >
                  <InfiniteSelect
                    query={COMPANIES}
                    variableSelector={({ skip, limit, search }) => ({
                      filter: {
                        skip,
                        limit,
                        search,
                      },
                      sort: {
                        sortBy: SortOrder.Desc,
                      },
                    })}
                    dataSelector={({ companies }) => {
                      return (
                        companies?.data?.map((item) => ({
                          label: `${item?.gstNumber} | ${item?.tradeName}`,
                          value: item?._id ?? '',
                        })) ?? []
                      );
                    }}
                    countSelector={({ companies }) => companies?.count ?? 0}
                    allowSearch
                    placeholder="GSTIN, tradename"
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  name={['filters', 'financialYear']}
                  label="Financial year"
                  className="select"
                >
                  <RangePicker
                    className="full-width"
                    placeholder={['From', 'To']}
                    picker="year"
                  />
                </Form.Item>
              </div>
            </Form>
            {filter?.companyId && (
              <LoaderComponent spinning={loading}>
                <div className="tables d-flex flex-vertical gap-24 full-width">
                  <div className="d-flex gap-16 full-width">
                    <div className="width-percent-50">
                      <Text>GSTR 1</Text>
                      <TableComponent
                        columns={columns}
                        dataSource={(data?.GSTR1 as TrackReturnStatus[]) || []}
                        locale={EMPTY_STATE}
                        scroll={{ y: 35 * 5 }}
                      />
                    </div>
                    <div className="width-percent-50">
                      <Text>GSTR 3B</Text>
                      <TableComponent
                        columns={columns}
                        dataSource={(data?.GSTR3B as TrackReturnStatus[]) || []}
                        locale={EMPTY_STATE}
                        scroll={{ y: 35 * 5 }}
                      />
                    </div>
                  </div>
                  <div className="d-flex gap-16 full-width">
                    <div className="width-percent-50">
                      <Text>GSTR 9</Text>
                      <TableComponent
                        columns={columns}
                        dataSource={(data?.GSTR9 as TrackReturnStatus[]) || []}
                        locale={EMPTY_STATE}
                        scroll={{ y: 35 * 5 }}
                      />
                    </div>
                    <div className="width-percent-50">
                      <Text>GSTR 9C</Text>
                      <TableComponent
                        columns={columns}
                        locale={EMPTY_STATE}
                        dataSource={(data?.GSTR9C as TrackReturnStatus[]) || []}
                        scroll={{ y: 35 * 5 }}
                      />
                    </div>
                  </div>
                </div>
              </LoaderComponent>
            )}
          </div>
        </Card>
      </div>
      <div className="d-flex mt-16"></div>
    </div>
  );
};

export default TrackReturnsStatus;
