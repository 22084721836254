import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  FetchResult,
  from,
  NextLink,
  Operation,
} from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom/client';
import { ROUTES } from './common/constants';
import { Cookie } from './common/utils';
import { messageContext } from './components/AppContextHolder';
import LogoutPopup from './components/LogoutPopup';

let disableToastTimeout: NodeJS.Timeout | null = null;
export const cacheData = new InMemoryCache();
const redirectProfile = `${process.env.REACT_APP_REDIRECT_PROFILE_URL}`;

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_URL,
});

const httpLinkCounselvise = createHttpLink({
  uri: process.env.REACT_APP_COUNSELVISE_SERVER_URL,
});

const toast = ({
  message: content,
  type,
}: {
  message: string;
  type: 'info' | 'success' | 'warning' | 'error';
}) => {
  messageContext?.destroy();
  switch (type) {
    case 'info':
      messageContext?.info(content);
      break;
    case 'success':
      messageContext?.success(content);
      break;
    case 'warning':
      messageContext?.warning(content);
      break;
    case 'error':
      messageContext?.error(content);
      break;
    default:
      break;
  }
};

const authLink = setContext((_, { headers }) => {
  const userToken = Cookie.get(Cookie.keys.TOKEN);

  let newHeaders = headers || {};

  newHeaders = {
    ...newHeaders,
    Authorization: userToken ? `Bearer ${userToken}` : '',
  };

  return {
    headers: newHeaders,
  };
});

const responseMessageLink = new ApolloLink(
  (operation: Operation, forward: NextLink) =>
    forward(operation)?.map((response: FetchResult) => {
      const { data } = response;
      const keys = Object.keys(data ?? {});
      if (keys?.length > 0 && data?.[`${keys?.[0]}`]?.message) {
        if (keys?.[0] === 'forgotUserPassword') {
          if (data?.[`${keys?.[0]}`]?.status !== 'ERROR') {
            setTimeout(() => {
              toast({
                message:
                  data?.[`${keys?.[0]}`]?.message || 'Operation successful',
                type: 'success',
              });
            }, 1000);
          }
        } else {
          setTimeout(() => {
            const oResponse = data?.[`${keys?.[0]}`];

            if (!response) {
              return;
            }

            if (
              !data.createAssessee ||
              !data.UpdateAssessee ||
              !data.DeleteAssessee
            ) {
              return;
            }

            toast({
              message: oResponse?.message || 'Operation successful',
              type: oResponse?.status === 'ERROR' ? 'error' : 'success',
            });
          }, 1000);
        }
      }
      return response;
    }),
);

const errorLink = onError((options: ErrorResponse) => {
  const { graphQLErrors, networkError, response } = options;
  if (networkError && 'statusCode' in networkError) {
    if (networkError.statusCode === 405) {
      if (disableToastTimeout) {
        clearTimeout(disableToastTimeout);
      }

      disableToastTimeout = setTimeout(() => {
        if (networkError.message) {
          toast({
            message: networkError.message,
            type: 'error',
          });
        }
      }, 200);
      window.open(redirectProfile, '_self');
      return;
    }
  }

  if (graphQLErrors && graphQLErrors?.length > 0) {
    const keys = Object.keys(response?.data ?? {});
    const isCreateAssessee = keys?.includes('createAssessee');
    const isUpdateAssessee = keys?.includes('updateAssessee');
    const isDeleteAssessee = keys?.includes('deleteAssessee');
    const isForBidden = graphQLErrors?.[0]?.extensions?.code === 'FORBIDDEN';
    const isOtherDeviceLoggedIn =
      graphQLErrors?.[0]?.extensions?.code === 'OTHER_DEVICE_LOGGED_IN';
    const isTokenExpired =
      graphQLErrors?.[0]?.extensions?.code === 'SESSION_EXPIRED';
    if (isCreateAssessee || isUpdateAssessee || isDeleteAssessee) {
      return;
    }
    if (!isForBidden) {
      if (isOtherDeviceLoggedIn) {
        let popupElement = document.getElementById('error-popup');
        if (!popupElement) {
          popupElement = document.createElement('div');
          popupElement.id = 'error-popup';
        }
        if (popupElement) {
          Cookie.clear();
          const errorRoot = ReactDOM.createRoot(popupElement);
          errorRoot?.render(<LogoutPopup errorElement={errorRoot} />);
        }
      } else {
        if (!isTokenExpired) {
          setTimeout(() => {
            toast({
              message: graphQLErrors?.[0]?.message,
              type: 'error',
            });
          }, 1000);
        }
      }
    } else {
      setTimeout(() => {
        toast({
          message: 'Something went wrong!',
          type: 'error',
        });
      }, 1000);
    }
  }

  if (response) {
    response?.errors?.map((error) => {
      const { message: errorMessage, locations, path, extensions } = error;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const exceptionName: any = extensions?.exception;

      // Enable when sentry integrated
      Sentry?.captureException(
        new Error(
          `[Response error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`,
        ),
      );

      if (extensions?.code === 'UNAUTHORIZED') {
        window.open(
          `${redirectProfile}/${ROUTES.DIRECT_TAX}/${ROUTES.NOTICE_BOARD}`,
          '_self',
        );
      }

      if (
        extensions?.code === 'SESSION_EXPIRED' ||
        extensions?.code === 'UNAUTHENTICATED' ||
        extensions?.code === 405 ||
        extensions?.code === 'INVALID_TOKEN' ||
        exceptionName?.name === 'JsonWebTokenError'
      ) {
        window.open(`${redirectProfile}${ROUTES.LOGIN}`, '_self');
      }
      if (extensions?.code === 'OTHER_DEVICE_LOGGED_IN') {
        window.open(redirectProfile, '_self');
      }

      // eslint-disable-next-line no-console
      return console?.log(
        `[Response error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`,
      );
    });
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console?.log(`[Network error]: ${networkError}`);
    Sentry?.captureException(new Error(`[Network error]: ${networkError}`));
  }
});

const client = new ApolloClient({
  cache: cacheData,
  link: from([responseMessageLink, errorLink, authLink, httpLink]),
});

export const counselviseClient = new ApolloClient({
  cache: cacheData,
  link: from([responseMessageLink, errorLink, authLink, httpLinkCounselvise]),
});

export default client;
