import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Form, Input, Row, Select, Space, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  CalenderData,
  DurationType,
  EProceeding,
  EProceedingCalenderCountResponse,
  EProceedingsFilter,
  EProceedingSortOn,
  EProceedingStatus,
  SortOrder,
} from '../../../__generated__/graphql';
import { counselviseClient } from '../../../apollo';
import {
  FETCH_USER_QUERY,
  SUBSCRIPTION_PLANS_QUERY,
} from '../../../app/components/Queries';
import {
  ActiveBox,
  Calendar,
  CloseNotification,
  FilePDF,
  Files,
  HourGlassLaw,
  Pen,
  Pencil,
  RegularCalendar,
  Users,
  Warning,
} from '../../../assets/svg';
import axiosInstance from '../../../common/axiosInstance';
import {
  COMMON_QUERY_PARAMS,
  CURRENT_PAGE,
  currentDate,
  defaultDateFormat,
  E_PROCEEDINGS_STATUS,
  EMPTY_DUE_DATES_TEXT,
  FEATURE_NOTICE_BOARD_DIRECT_TAX,
  GUTTER_VARIATIONS,
  LIMIT,
  MAX_LIST_NOTICE,
  MESSAGE,
  ROUTES,
  upComingDays,
  ZERO_PLACEHOLDER_TEXT,
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import TableComponent from '../../../components/CommonTable';
import Overview from '../../../components/dashboard/Overview';
import SyncCalendar from '../../../components/dashboard/SyncCalendar';
import LoaderComponent from '../../../components/LoaderComponent';
import CommonModal from '../../../components/modals/CommonModal';
import useRouter from '../../../hooks/useRouter';
import {
  assesseeStats,
  EProceedingCalendarData,
  FormValues,
  StatisticData,
} from '../../../types/common.type';
import { UPDATE_E_PROCEEDING_STATUS } from '../tax-litigation/graphql/Mutation';
import PlansCards from './component/PlansCards';
import './dashboard.less';
import { SubscriptionPlan } from './graphql/dashboard.type';
import { DISCONNECT_CALENDER } from './graphql/Mutation';
import {
  ASSESSEE_STATES,
  CALENDAR_COUNT,
  CURRENT_SYNC_CALENDAR,
  E_PROCEEDINGS,
} from './graphql/Queries';
const { Text } = Typography;
const { required } = formValidatorRules;

const initialFilter = {
  limit: MAX_LIST_NOTICE,
  duration: DurationType?.Custom,
  period: {
    start: currentDate,
    end: upComingDays,
  },
  isIssuedOn: false,
  status: EProceedingStatus?.Pending,
} as EProceedingsFilter;

const generateStatisticData = (
  assesseeStats: assesseeStats,
  iconColor?: string,
): StatisticData[] => {
  return [
    {
      icon: <Pen color={iconColor} />,
      label: 'All Notices',
      value: assesseeStats?.allNotices || ZERO_PLACEHOLDER_TEXT,
      key: DurationType?.All,
    },
    {
      icon: <Files color={iconColor} />,
      label: 'Pending Notices',
      value: assesseeStats?.openNotices || ZERO_PLACEHOLDER_TEXT,
    },
    {
      icon: <Calendar color={iconColor} />,
      label: 'Due Today',
      value: assesseeStats?.dueToday || ZERO_PLACEHOLDER_TEXT,
      key: DurationType?.DueToday,
    },
    {
      icon: <ActiveBox color={iconColor} />,
      label: '7 Days Due',
      value: assesseeStats?.oneWeekDue || ZERO_PLACEHOLDER_TEXT,
      key: DurationType?.DueIn_7Days,
    },
    {
      icon: <HourGlassLaw color={iconColor} />,
      label: 'Last 24 Hours',
      value: assesseeStats?.last24Hours || ZERO_PLACEHOLDER_TEXT,
      key: DurationType?.Last_24Hours,
    },
    {
      icon: <RegularCalendar color={iconColor} />,
      label: 'OverDue',
      value: assesseeStats?.overDue || ZERO_PLACEHOLDER_TEXT,
      key: DurationType?.OverDues,
    },
    {
      icon: <Users color={iconColor} />,
      label: 'Total PAN',
      value: assesseeStats?.totalPan || ZERO_PLACEHOLDER_TEXT,
      key: 'allPan',
    },
    {
      icon: <Warning color={iconColor} />,
      label: 'Failed Login',
      value: assesseeStats?.loginFailed || ZERO_PLACEHOLDER_TEXT,
      key: 'failedLogin',
    },
  ];
};

const Dashboard = () => {
  const dateFormat = 'YYYY/MM/DD';
  const startOfMonth = dayjs().startOf('month').startOf('day');
  const endOfMonth = dayjs().endOf('month').endOf('day');
  const [statusForm] = Form.useForm();
  const { navigate } = useRouter();
  const [plansModalOpen, setPlansModalOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDateResponses, setSelectedDataResponses] =
    useState<EProceedingCalendarData>();
  const [selectedDateRange, setSelectedDateRange] = useState({
    end: endOfMonth,
    start: startOfMonth,
  });
  const [searchParams] = useSearchParams();

  const [isNotificationVisible, setNotificationVisible] =
    useState<boolean>(false);

  const { data: { currentUser } = {} } = useQuery(FETCH_USER_QUERY, {
    client: counselviseClient,
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.currentUser?.activeSubscriptions) {
        setNotificationVisible(true);
      }
    },
  });

  const { data: { assesseeStats } = {}, loading: loadStats } = useQuery(
    ASSESSEE_STATES,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const {
    data: { eProceedings } = {},
    refetch: refetchEProceedings,
    loading: loadNotices,
  } = useQuery(E_PROCEEDINGS, {
    fetchPolicy: 'network-only',
    onError() {},
    variables: {
      filter: initialFilter,
      sort: {
        sortBy: SortOrder.Asc,
        sortOn: EProceedingSortOn?.ResponseDueDate,
      },
    },
  });
  const { data: { eProceedingsCalenderCount } = {}, refetch: refetchCounts } =
    useQuery(CALENDAR_COUNT, {
      skip: !endOfMonth && !startOfMonth,
      variables: {
        filter: {
          duration: {
            end: selectedDateRange.end || endOfMonth,
            start: selectedDateRange.start || startOfMonth,
          },
        },
      },
      onError() {},
      onCompleted() {},
    });

  const [updateStatus, { loading: updateStatusLoading }] = useMutation(
    UPDATE_E_PROCEEDING_STATUS,
    {
      onError() {},
      onCompleted: () => {
        statusForm.resetFields();
        setIsModalOpen(false);
        refetchEProceedings();
        refetchCounts();
      },
    },
  );
  const { data: { subscriptionPlans } = {}, loading: plansLoading } = useQuery(
    SUBSCRIPTION_PLANS_QUERY,
    {
      client: counselviseClient,
      onError() {},
      fetchPolicy: 'network-only',
    },
  );

  const {
    data: { currentUserCalender } = {},
    loading: currentSyncCalendarLoading,
    refetch,
  } = useQuery(CURRENT_SYNC_CALENDAR, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (endOfMonth && startOfMonth) {
      refetchCounts({
        filter: {
          duration: {
            end: dayjs(selectedDateRange?.end || endOfMonth, dateFormat),
            start: dayjs(selectedDateRange?.start || startOfMonth, dateFormat),
          },
        },
      });
    }
  }, [endOfMonth, startOfMonth, selectedDateRange]);

  const [disconnectCalendar] = useMutation(DISCONNECT_CALENDER, {
    onCompleted: () => {
      refetch();
    },
  });

  const calendarDisconnected = () => {
    disconnectCalendar();
  };

  const getSelectedRange = (dateString: [string, string]) => {
    setSelectedDateRange({
      start: dayjs(dateString[0]).startOf('month').startOf('day'),
      end: dayjs(dateString[1]).endOf('month').endOf('day'),
    });
  };

  const filteredPlans = subscriptionPlans?.data?.filter((plan) =>
    plan?.features?.includes(FEATURE_NOTICE_BOARD_DIRECT_TAX),
  ) as SubscriptionPlan[];

  const latestSubscription = currentUser?.activeSubscriptions?.reduce(
    (latest, current) => {
      const latestDate = dayjs(latest?.createdAt || 0);
      const currentDate = dayjs(current?.createdAt || 0);
      return currentDate.isAfter(latestDate) ? current : latest;
    },
    null,
  );

  const activePlan =
    currentUser?.activeSubscriptions?.find((item) =>
      item?.features?.includes(FEATURE_NOTICE_BOARD_DIRECT_TAX),
    ) && latestSubscription?.subscriptionPlan
      ? latestSubscription
      : null;

  const updateStateForm = (value: FormValues) => {
    updateStatus({
      variables: {
        where: {
          ids: value?.statusForm?.id,
        },
        data: {
          remarkByAdmin: value?.statusForm?.remark,
          status: value?.statusForm?.status,
        },
      },
    });
  };

  const getSelectedNotice = (notice: EProceedingCalendarData) => {
    setSelectedDataResponses(notice);
  };

  const syncCalendarWith = async () => {
    if (searchParams.get('scope')) {
      await axiosInstance.post(
        `/v1/direct-tax-calender/google-calendar-redirect?code=${searchParams.get('code')}`,
      );
    } else {
      await axiosInstance.post(
        `/v1/direct-tax-calender/outlook-redirect?code=${searchParams.get('code')}`,
      );
    }
    refetch();
    navigate(`/${ROUTES.DIRECT_TAX}`, { replace: true });
  };

  useEffect(() => {
    if (searchParams.get('code')) syncCalendarWith();
  }, [searchParams.get('code')]);

  const handleNavigation = () => {
    const upcoming3Days = dayjs().add(2, 'day').format(defaultDateFormat);
    const currentDate = dayjs().format(defaultDateFormat);
    const queryParams = {
      [COMMON_QUERY_PARAMS.STATUS]: EProceedingStatus.Pending,
      [COMMON_QUERY_PARAMS.DURATION]: DurationType.Custom,
      [COMMON_QUERY_PARAMS.START]: currentDate,
      [COMMON_QUERY_PARAMS.END]: upcoming3Days,
    };
    navigate(
      `/${ROUTES?.DIRECT_TAX}/${ROUTES?.TAX_LITIGATION}?${new URLSearchParams(queryParams).toString()}`,
    );
  };

  const cancelModal = () => {
    setPlansModalOpen(false);
    setIsModalOpen(false);
    statusForm.resetFields();
  };

  const columns: ColumnType<EProceeding>[] = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      fixed: 'left',
      render: (_val, _, i) => i + 1 + LIMIT * (CURRENT_PAGE - 1),
    },
    {
      title: 'PAN',
      key: 'username',
      fixed: 'left',
      render: (item) => (
        <a
          onClick={() =>
            navigate(
              `${ROUTES?.CLIENT}/${ROUTES?.PAN_DASHBOARD.replace(':id', item?.assessee?._id)}`,
            )
          }
        >
          {item?.assessee?.username}
        </a>
      ),
    },
    {
      title: 'Name of Assessee',
      dataIndex: ['assessee', 'name'],
      key: 'name',
      render: (text) => text ?? '-',
    },
    {
      title: 'Reference ID',
      dataIndex: 'noticeReferenceId',
      key: 'noticeReferenceId',
      render: (text) => text || '-',
    },
    {
      title: 'A.Y.',
      dataIndex: 'assessmentYear',
      key: 'assessmentYear',
      render: (text) => text || '-',
    },
    {
      title: 'F.Y.',
      dataIndex: 'financialYear',
      key: 'financialYear',
      render: (text) => text || '-',
    },
    {
      title: 'Due Date',
      dataIndex: 'responseDueDate',
      key: 'responseDueDate',
      render: (responseDueDate) => {
        if (!responseDueDate) return '-';

        const formattedDate =
          dayjs?.(responseDueDate).format(defaultDateFormat);

        return <span className="notice-due-date">{formattedDate}</span>;
      },
    },
    {
      title: 'Action',
      fixed: 'right',
      render: (record) => (
        <Space>
          <Link
            className="pointer"
            to={record?.letterPdf?.attachments?.[0]?.url ?? ''}
            download={record?.letterPdf?.attachments?.[0]?.url}
            target="_blank"
          >
            <FilePDF />
          </Link>
          <span
            key="edit"
            onClick={() => {
              setIsModalOpen(true);
              statusForm.setFieldsValue({
                statusForm: {
                  status: record.status,
                  remark: record.remarkByAdmin,
                  id: [record._id],
                },
              });
            }}
            className="pointer"
          >
            <Pencil />
          </span>
        </Space>
      ),
    },
  ];

  const loading = loadNotices || loadStats;

  return (
    <LoaderComponent spinning={loading}>
      <div className="dashboard-content-holder relative">
        {!activePlan &&
        isNotificationVisible &&
        !((currentUser?.userClient?.directTaxUsedCount ?? 0) >= 2) ? (
          <div className="plan-notification">
            <Text>
              Hey! Your “Free Trial” package has 2 free clients.{' '}
              <span onClick={() => setPlansModalOpen(true)} className="pointer">
                Get more clients
              </span>
            </Text>
            <span
              onClick={() => setNotificationVisible(false)}
              className="pointer"
            >
              <CloseNotification />
            </span>
          </div>
        ) : null}
        <div className="container">
          <div className="dashboard-content-wrapper mt-32 mb-32">
            <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}>
              <Col md={6} lg={6} className="section-1">
                <SyncCalendar
                  eProceedingsCalenderCount={
                    eProceedingsCalenderCount as EProceedingCalenderCountResponse
                  }
                  getSelectedNotice={getSelectedNotice}
                  selectedDateResponses={
                    selectedDateResponses as EProceedingCalendarData
                  }
                  getSelectedRange={getSelectedRange}
                  googleCalendarRedirectLink="/v1/direct-tax-calender/google-calendar-auth"
                  outlookCalendarRedirectLink="/v1/direct-tax-calender/outlook-login"
                  currentUserCalender={currentUserCalender as CalenderData}
                  currentUserCalenderLoading={currentSyncCalendarLoading}
                  calendarDisconnected={calendarDisconnected}
                />
              </Col>
              <Col md={18} lg={18}>
                <Overview
                  statisticData={generateStatisticData(
                    assesseeStats as assesseeStats,
                    '#7A2976',
                  )}
                />
                <div className="mt-32">
                  <Text className="head">Nearing Due Dates</Text>
                  <TableComponent<EProceeding>
                    rowKey="_id"
                    columns={columns}
                    dataSource={eProceedings?.data as EProceeding[]}
                    className=" mt-8"
                    scroll={{ x: 'max-content' }}
                    locale={EMPTY_DUE_DATES_TEXT}
                  />
                  <Button
                    type="link"
                    className="underline-btn float-right"
                    onClick={handleNavigation}
                  >
                    View All
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <CommonModal
        open={plansModalOpen}
        footer={false}
        closable={true}
        onCancel={cancelModal}
        maskClosable={false}
        centered={true}
        className="plans-container"
        width={1086}
      >
        <PlansCards
          activePlan={activePlan}
          plans={filteredPlans}
          loading={plansLoading}
          userClient={currentUser?.userClient}
        />
      </CommonModal>
      <CommonModal
        open={isModalOpen}
        title="Update Status"
        footer={false}
        closable={true}
        onCancel={cancelModal}
        maskClosable={false}
      >
        <div className="create-forms-form">
          <Form
            onFinish={updateStateForm}
            form={statusForm}
            layout="vertical"
            preserve={false}
          >
            <Form.Item
              label="Status"
              name={['statusForm', 'status']}
              rules={[
                { ...required, message: MESSAGE?.required, whitespace: true },
              ]}
            >
              <Select
                options={E_PROCEEDINGS_STATUS}
                placeholder="Change Status"
              />
            </Form.Item>
            <Form.Item
              label="Remark"
              name={['statusForm', 'remark']}
              rules={[{ whitespace: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Remark" name={['statusForm', 'id']} hidden>
              <Select mode="multiple" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="full-width"
              loading={updateStatusLoading}
            >
              Update
            </Button>
          </Form>
        </div>
      </CommonModal>
    </LoaderComponent>
  );
};

export default Dashboard;
