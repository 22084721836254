import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Flex,
  Popover,
  Row,
  Spin,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import {
  CalenderData,
  DurationType,
  EProceedingCalenderCountResponse,
  EProceedingStatus,
} from '../../__generated__/graphql';
import { GoogleCalendar, Outlook, ThreeDotIcon } from '../../assets/svg';
import axiosInstance from '../../common/axiosInstance';
import {
  COMMON_QUERY_PARAMS,
  defaultDateFormat,
  ROUTES,
} from '../../common/constants';
import useRouter from '../../hooks/useRouter';
import { EProceedingCalendarData } from '../../types/common.type';
import CommonCalendar from '../CommonCalendar';
import { CURRENT_SYNC_CALENDAR } from '../../modules/direct-tax/dashboard/graphql/Queries';
import { useQuery } from '@apollo/client';

const { Text } = Typography;

const RenderCalendarSyncButton = ({
  googleCalendarRedirectLink,
  outlookCalendarRedirectLink,
  calendarDisconnected,
}: {
  googleCalendarRedirectLink: string;
  outlookCalendarRedirectLink: string;
  currentUserCalender: CalenderData;
  calendarDisconnected: () => void;
}) => {
  const [open, setOpen] = useState(false);

  const {
    data: { currentUserCalender: currentUserCalendarData } = {},
    loading: currentSyncCalendarLoading,
  } = useQuery(CURRENT_SYNC_CALENDAR, {
    fetchPolicy: 'network-only',
  });

  if (currentSyncCalendarLoading) {
    return (
      <Spin
        spinning
        size="small"
        className="d-flex justify-center align-center flex-vertical"
      />
    );
  }

  if (currentUserCalendarData?.type === 'GOOGLE') {
    return (
      <div className="d-flex justify-between align-center full-width">
        <span className="d-flex align-center gap-2">
          <span className="d-flex pointer">
            <GoogleCalendar />
          </span>
          Google Calendar
        </span>
        <span className="d-flex">
          <Popover
            content={
              <span
                className="color-main disconnect-btn"
                onClick={() => {
                  calendarDisconnected();
                }}
              >
                Disconnect
              </span>
            }
            trigger="click"
            open={open}
            onOpenChange={() => setOpen((prev) => !prev)}
            placement="bottomRight"
          >
            <span className="pointer">
              <ThreeDotIcon />
            </span>
          </Popover>
        </span>
      </div>
    );
  }
  if (currentUserCalendarData?.type === 'OUTLOOK') {
    return (
      <div className="d-flex justify-between align-center full-width">
        <span className="d-flex align-center gap-2">
          <span className="d-flex pointer">
            <Outlook />
          </span>
          Outlook Calendar
        </span>
        <span className="d-flex">
          <Popover
            content={
              <span
                className="color-main disconnect-btn"
                onClick={() => {
                  calendarDisconnected();
                }}
              >
                Disconnect
              </span>
            }
            trigger="click"
            open={open}
            onOpenChange={() => setOpen((prev) => !prev)}
            placement="bottomRight"
          >
            <span className="pointer">
              <ThreeDotIcon />
            </span>
          </Popover>
        </span>
      </div>
    );
  }
  return (
    <div className="sync-calendar">
      <Text>Sync your Calendar</Text>
      <Flex gap={4}>
        <Button
          block
          icon={<GoogleCalendar />}
          className="d-flex align-center justify-center"
          onClick={async () => {
            const response = await axiosInstance.post(
              googleCalendarRedirectLink,
            );
            if (response?.data) {
              window.open(response.data?.message, '_self');
            }
          }}
        >
          Google
        </Button>
        {process.env.REACT_APP_FEATURE_OUTLOOK === 'true' && (
          <Button
            block
            icon={<Outlook />}
            className="d-flex align-center justify-center"
            onClick={async () => {
              const response = await axiosInstance.get(
                outlookCalendarRedirectLink,
              );
              if (response?.data) {
                window.open(response.data?.authUrl, '_self');
              }
            }}
          >
            Outlook
          </Button>
        )}
      </Flex>
    </div>
  );
};

const SyncCalendar = ({
  eProceedingsCalenderCount,
  getSelectedRange,
  googleCalendarRedirectLink,
  outlookCalendarRedirectLink,
  currentUserCalender,
  currentUserCalenderLoading,
  calendarDisconnected,
}: {
  eProceedingsCalenderCount: EProceedingCalenderCountResponse;
  getSelectedNotice: (notice: EProceedingCalendarData) => void;
  selectedDateResponses: EProceedingCalendarData;
  getSelectedRange: (dateString: [string, string]) => void;
  googleCalendarRedirectLink: string;
  outlookCalendarRedirectLink: string;
  currentUserCalender: CalenderData;
  currentUserCalenderLoading: boolean;
  calendarDisconnected: () => void;
}) => {
  const { navigate } = useRouter();
  const handleSelectedNotice = (notice: EProceedingCalendarData) => {
    if (notice?.date) {
      const selectedDate = dayjs(notice?.date, defaultDateFormat).format(
        defaultDateFormat,
      );
      const queryParams = {
        [COMMON_QUERY_PARAMS.STATUS]: EProceedingStatus.Pending,
        [COMMON_QUERY_PARAMS.DURATION]: DurationType.Custom,
        [COMMON_QUERY_PARAMS.START]: selectedDate,
        [COMMON_QUERY_PARAMS.END]: selectedDate,
      };
      navigate(
        `${ROUTES?.TAX_LITIGATION}?${new URLSearchParams(queryParams).toString()}`,
      );
    }
  };

  return (
    <Card className="mr-24">
      <CommonCalendar
        calenderCountList={
          eProceedingsCalenderCount as EProceedingCalenderCountResponse
        }
        getSelectedNotice={handleSelectedNotice}
        getSelectedRange={getSelectedRange}
      />
      <Card className="mt-16 mb-16">
        <Row className="d-flex  align-self-stretch gap-16">
          <Col className="d-flex flex-wrap align-center">
            <div className="color-box bg-blue" />
            <span className="ml-10 color-blue">
              {eProceedingsCalenderCount?.totalIssuedOn ?? 0} notice issued
            </span>
          </Col>
          <Col className="d-flex flex-wrap align-center">
            <div className="color-box bg-red" />
            <span className="ml-10 color-red">
              {eProceedingsCalenderCount?.totalResponseDueCount ?? 0} notice due
            </span>
          </Col>
          <Col className="d-flex align-center">
            <div className="color-box bg-orange" />
            <span className="ml-10 color-orange">
              {eProceedingsCalenderCount?.totalIssuedOn ?? 0} notice issues and{' '}
              {eProceedingsCalenderCount?.totalResponseDueCount ?? 0} dues
            </span>
          </Col>
        </Row>
      </Card>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              defaultBorderColor: 'var(--textDark)',
              defaultColor: 'var(--textDark)',
              fontWeight: 500,
              defaultHoverColor: 'var(--textDark)',
              defaultHoverBorderColor: 'var(--textDark)',
            },
          },
        }}
      >
        {!currentUserCalenderLoading && (
          <RenderCalendarSyncButton
            currentUserCalender={currentUserCalender}
            googleCalendarRedirectLink={googleCalendarRedirectLink}
            outlookCalendarRedirectLink={outlookCalendarRedirectLink}
            calendarDisconnected={calendarDisconnected}
          />
        )}
      </ConfigProvider>
    </Card>
  );
};

export default SyncCalendar;
