import { CloseCircleOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Result, Typography } from 'antd';
import { useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import App from './app/App';
import {
  ERROR_PAGE_SUBTITLE,
  ERROR_PAGE_TITLE,
  ROUTES,
} from './common/constants';
import LoaderComponent from './components/LoaderComponent';
import MaintenancePage from './components/MaintenancePage';
import Error404 from './Error404';
import useRouter from './hooks/useRouter';
import Clients from './modules/direct-tax/clients/Clients';
import PanDashboard from './modules/direct-tax/clients/PanDashboard';
import SyncLog from './modules/direct-tax/clients/SyncLog';
import DirectTaxDashboard from './modules/direct-tax/dashboard/Dashboard';
import FiledReturn from './modules/direct-tax/filed-return/FiledReturn';
import FiledReturnStatus from './modules/direct-tax/filed-return/FilesReturnStatus';
import OtherFiled from './modules/direct-tax/other-filed/OtherFiled';
import OtherFiledResponse from './modules/direct-tax/other-filed/OtherFiledResponse';
import EProcessingResponse from './modules/direct-tax/tax-litigation/EProcessingResponse';
import TaxLitigation from './modules/direct-tax/tax-litigation/TaxLitigation';
import InDirectTaxClients from './modules/indirect-tax/companies/Companies';
import InDirectTaxCompanyDashboard from './modules/indirect-tax/companies/CompanyDashboard';
import InDirectTaxSyncLog from './modules/indirect-tax/companies/SyncLog';
import InDirectTaxDashboard from './modules/indirect-tax/dashboard/Dashboard';
import AdditionalNoticeOrderDetail from './modules/indirect-tax/notice/AdditionalNoticeOrderDetail';
import Notice from './modules/indirect-tax/notice/Notice';
import NoticesOrdersDetail from './modules/indirect-tax/notice/NoticesOrdersDetail';
import TrackReturnStatus from './modules/indirect-tax/track-return-status/TrackReturnStatus';
import PrivateRoute from './PrivateRoute';

type FallbackProps = {
  error: Error | null;
  componentStack: string | null;
};

const { Paragraph } = Typography;

const MyFallbackComponent = ({ error, componentStack }: FallbackProps) => (
  <Result
    status="error"
    title={ERROR_PAGE_TITLE}
    subTitle={ERROR_PAGE_SUBTITLE}
  >
    <div className="desc">
      <Paragraph>
        <Typography.Title level={4}> Error:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {error?.message?.toString()}
      </Paragraph>
      <Paragraph>
        <Typography.Title level={4}> Stacktrace:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {componentStack}
      </Paragraph>
    </div>
  </Result>
);

const RoutesCollection = () => {
  const DASHBOARD = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.MAIN,
              element: <Navigate to={ROUTES?.DIRECT_TAX} replace />,
            },
          ],
        },
      ],
    },
  ];

  const DIRECT_TAX_DASHBOARD = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.DIRECT_TAX,
              element: <DirectTaxDashboard />,
            },
          ],
        },
      ],
    },
  ];

  const INDIRECT_TAX_DASHBOARD = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.INDIRECT_TAX,
              element: <InDirectTaxDashboard />,
            },
          ],
        },
      ],
    },
  ];

  const DIRECT_TAX_DASHBOARD_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.DIRECT_TAX,
              children: [
                {
                  path: ROUTES?.CLIENT,
                  element: <Clients />,
                },
                {
                  path: ROUTES?.FILED_RETURN,
                  element: <FiledReturn />,
                },
                {
                  path: ROUTES?.OTHER_FILED,
                  element: <OtherFiled />,
                },
                {
                  path: ROUTES?.TAX_LITIGATION,
                  element: <TaxLitigation />,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const INDIRECT_TAX_DASHBOARD_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.INDIRECT_TAX,
              children: [
                {
                  path: ROUTES?.COMPANY,
                  element: <InDirectTaxClients />,
                },
                {
                  path: ROUTES?.NOTICE_ORDERS,
                  element: <Notice />,
                },
                {
                  path: ROUTES?.ADDITIONAL_NOTICE_ORDERS,
                  element: <Notice />,
                },
                {
                  path: ROUTES?.TRACK_RETURN_STATUS,
                  element: <TrackReturnStatus />,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const INDIRECT_TAX_NOTICE_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.INDIRECT_TAX,
              children: [
                {
                  path: ROUTES?.NOTICE_ORDERS,
                  children: [
                    {
                      path: ROUTES?.DETAIL,
                      element: <NoticesOrdersDetail />,
                    },
                  ],
                },
              ],
            },
            {
              path: ROUTES?.INDIRECT_TAX,
              children: [
                {
                  path: ROUTES?.ADDITIONAL_NOTICE_ORDERS,
                  children: [
                    {
                      path: ROUTES?.DETAIL,
                      element: <AdditionalNoticeOrderDetail />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const CLIENT_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.DIRECT_TAX,
              children: [
                {
                  path: ROUTES?.CLIENT,
                  children: [
                    {
                      path: ROUTES?.SYNC_LOG,
                      element: <SyncLog />,
                    },
                    {
                      path: ROUTES?.PAN_DASHBOARD,
                      element: <PanDashboard />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const INDIRECT_TAX_CLIENT_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.INDIRECT_TAX,
              children: [
                {
                  path: ROUTES?.COMPANY,
                  children: [
                    {
                      path: ROUTES?.SYNC_LOG,
                      element: <InDirectTaxSyncLog />,
                    },
                    {
                      path: ROUTES?.COMPANY_DASHBOARD,
                      element: <InDirectTaxCompanyDashboard />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const STATS_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.DIRECT_TAX,
              children: [
                {
                  path: ROUTES?.CLIENT,
                  children: [
                    {
                      path: ROUTES?.PAN_DASHBOARD,
                      children: [
                        {
                          path: ROUTES?.TAX_LITIGATION,
                          element: <TaxLitigation />,
                        },
                        {
                          path: ROUTES?.FILED_RETURN,
                          element: <FiledReturn />,
                        },
                        {
                          path: ROUTES?.OTHER_FILED,
                          element: <OtherFiled />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  const STATS_MODULES_RESPONSE = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.DIRECT_TAX,
              children: [
                {
                  path: ROUTES?.CLIENT,
                  children: [
                    {
                      path: ROUTES?.PAN_DASHBOARD,
                      children: [
                        {
                          path: ROUTES?.TAX_LITIGATION,
                          children: [
                            {
                              path: ROUTES?.E_PROCESSING_RESPONSE,
                              element: <EProcessingResponse />,
                            },
                          ],
                        },
                        {
                          path: ROUTES?.FILED_RETURN,
                          children: [
                            {
                              path: ROUTES?.FILED_RETURN_STATUS,
                              element: <FiledReturnStatus />,
                            },
                          ],
                        },
                        {
                          path: ROUTES?.OTHER_FILED,
                          children: [
                            {
                              path: ROUTES?.OTHER_FILED_DETAILS,
                              element: <OtherFiledResponse />,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const INDIRECT_TAX_STATS_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.INDIRECT_TAX,
              children: [
                {
                  path: ROUTES?.COMPANY,
                  children: [
                    {
                      path: ROUTES?.COMPANY_DASHBOARD,
                      children: [
                        {
                          path: ROUTES?.NOTICE_ORDERS,
                          element: <Notice />,
                        },
                        {
                          path: ROUTES?.TRACK_RETURN_STATUS,
                          element: <TrackReturnStatus />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const FILED_RETURN_FORM_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.DIRECT_TAX,
              children: [
                {
                  path: ROUTES?.FILED_RETURN,
                  children: [
                    {
                      path: ROUTES?.FILED_RETURN_STATUS,
                      element: <FiledReturnStatus />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const OTHER_FILED_FORM_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.DIRECT_TAX,
              children: [
                {
                  path: ROUTES?.OTHER_FILED,
                  children: [
                    {
                      path: ROUTES?.OTHER_FILED_DETAILS,
                      element: <OtherFiledResponse />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const E_PROCESSING_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.DIRECT_TAX,
              children: [
                {
                  path: ROUTES?.TAX_LITIGATION,
                  children: [
                    {
                      path: ROUTES?.E_PROCESSING_RESPONSE,
                      element: <EProcessingResponse />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const OTHER_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <App />,
          children: [
            {
              path: '*',
              element: <Error404 />,
            },
          ],
        },
      ],
    },
  ];

  const element = useRoutes([
    ...DASHBOARD,
    ...INDIRECT_TAX_DASHBOARD,
    ...DIRECT_TAX_DASHBOARD,
    ...DIRECT_TAX_DASHBOARD_MODULES,
    ...INDIRECT_TAX_DASHBOARD_MODULES,
    ...INDIRECT_TAX_NOTICE_MODULES,
    ...CLIENT_MODULES,
    ...INDIRECT_TAX_CLIENT_MODULES,
    ...INDIRECT_TAX_STATS_MODULES,
    ...STATS_MODULES,
    ...STATS_MODULES_RESPONSE,
    ...FILED_RETURN_FORM_MODULES,
    ...OTHER_FILED_FORM_MODULES,
    ...E_PROCESSING_MODULES,
    ...OTHER_MODULES,
  ]);
  return element;
};

const RoutesWrapper = () => {
  const loading = false;
  const { location } = useRouter();

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  useEffect(() => {
    if (location.pathname.includes(ROUTES.INDIRECT_TAX)) {
      document.body.classList.add('indirect-tax');
    }
    return () => document.body.classList.remove('indirect-tax');
  }, [location.pathname]);

  if (loading) return <LoaderComponent />;

  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      {maintenance === 'true' ? <MaintenancePage /> : <RoutesCollection />}
    </Sentry.ErrorBoundary>
  );
};
export default RoutesWrapper;
